<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="bannerImage"
        :title="'联 系 我 们'"
        :links="links"
      ></moduletitle>
      <div class="message">
        <div class="map">
          <div class="mapLeft" id="container"></div>
          <div class="mapRight">
            <div class="mapRight1">
              <div style="font-size: 2vw">联系我们</div>
              <div>CONTACT US</div>
            </div>
            <div class="mapRight2">
              <div class="mapRight2-item">
                <img src="../../assets/image/c1.png" alt="" />
                <span>电话：025-52802986</span>
              </div>
              <div class="mapRight2-item">
                <img src="../../assets/image/c2.png" alt="" />
                <span>传真：025-52802115</span>
              </div>
              <div class="mapRight2-item">
                <img src="../../assets/image/c3.png" alt="" />
                <span>邮箱：market@huamai.cn</span>
              </div>
              <div class="mapRight2-item">
                <img src="../../assets/image/c4.png" alt="" />
                <span>邮编：211103</span>
              </div>
            </div>
            <div class="mapRight3">
              <div style="font-size: 2vw">联系地址</div>
              <div>江苏省南京市江宁区</div>
              <div>东山国际企业研发园润发路11号</div>
            </div>
          </div>
        </div>
        <!-- <p style="text-align:center;font-size:18px;color:#314257;padding:3vw 0 .5vw 0">PRODUCT PURCHASE AND CONSULTATION</p>
            <p style="text-align:center;font-size:36px;color:#083875;font-weight:bold">产品购买及咨询</p> -->
        <!-- <div class="cardList">
                <div class="card card01" v-for="index in 4" :key="index" style="width:23.5%" @mouseenter="indexA = index"  @mouseleave="indexA = 0">
                    <div :style="{background:indexA == index?'url('+ require('../../assets/image/service.png') +') no-repeat center/cover':'#F6F6F6'}">
                        <span :style="{color:indexA == index?'#fff':'#000'}" style="font-size:1.2vw;padding: 1vw 0 .5vw 0;display:inline-block;border-bottom:1px solid #DCDCDC;margin-left:2vw">{{setName(index)}}</span>
                        <p :style="{color:indexA == index?'#fff':'#1D5D91'}"  style="padding:.7vw 2vw 0 2vw;font-size:.8vw">咨询运营商系统平台开发、金融行业平台建设、信息化平台建设</p>
                    </div>
                    <div class="cardBottom">
                        <div style="padding-left:1vw;font-size:.8vw">
                            <img src="../../assets/image/contactUs01.png" style="width:1.1vw" alt="">
                            <span>联系人：{{index == 2?'xxx':'石经理'}}</span>
                        </div>
                        <div style="padding-left:1vw;font-size:.8vw">
                            <img src="../../assets/image/contactUs02.png" style="width:1.1vw" alt="">
                            <span>联系电话：{{index == 1?'025-52802986':'025-52707622'}}</span>
                        </div>
                        <div style="padding-left:1vw;font-size:.8vw">
                            <img src="../../assets/image/contactUs03.png" style="width:1.1vw" alt="">
                            <span>邮箱：{{index == 1?'market@huamai.cn':'13032551231@163.com'}}</span>
                        </div>
                    </div>
                </div>
            </div> -->

        <!-- <p style="text-align:center;font-size:1vw;color:#314257;padding:3vw 0 .5vw 0">BUSINESS COOPERATION AND SERVICE SUPPORT</p>
            <p style="text-align:center;font-size:2vw;color:#083875;font-weight:bold">商业合作及服务支持</p>
            <div class="cardList">
                <div class="card card02" v-for="index in 3" :key="index" @mouseenter="indexA02 = index"  @mouseleave="indexA02 = 0">
                    <div :style="{background:indexA02 == index?'url('+ require('../../assets/image/service.png') +') no-repeat center/cover':'#F6F6F6'}">
                        <span :style="{color:indexA02 == index?'#fff':'#000'}" style="font-size:1.2vw;padding: 1vw 0 .5vw 0;display:inline-block;border-bottom:1px solid #DCDCDC;margin-left:2vw">{{setName02(index)}}</span>
                        <p :style="{color:indexA02 == index?'#fff':'#1D5D91'}" style="color:#1D5D91;padding:.7vw 2vw 0 2vw;font-size:1vw">咨询运营商系统平台开发、金融行业平台建设、信息化平台建设</p>
                    </div>
                    <div class="cardBottom">
                        <div>
                            <img src="../../assets/image/contactUs01.png" style="width:1.3vw" alt="">
                            <span>联系人：石经理</span>
                        </div>
                        <div>
                            <img src="../../assets/image/contactUs02.png" style="width:1.3vw" alt="">
                            <span>联系电话：025-52707622</span>
                        </div>
                        <div>
                            <img src="../../assets/image/contactUs03.png" style="width:1.3vw" alt="">
                            <span>邮箱：13032551231@163.com</span>
                        </div>
                    </div>
                </div>
            </div> -->
      </div>
      <div class="office">
        <p class="title">各地办事处联系方式</p>
        <div class="office-block">
          <div class="office-item" v-for="(item,index) in addressList" :key="index">
            <div class="title">
              <span>{{item.title}}</span>
            </div>
            <div class="contactWay" v-for="(i,ind) in item.children" :key="ind">
              <div>
                <img
                  src="./../../assets/image/contact/addressIcon.png"
                  style="width:1vw;height:1vw;margin-top:0.4vw;margin-right:0.5vw;"
                />
                <span
                  >地址{{item.children.length>1?(ind+1):''}}：{{i.address}}</span
                >
              </div>
              <div v-if="i.tel.length">
                <img
                  src="./../../assets/image/contact/telIcon.png"
                  style="width:1vw;height:1vw;margin-top:0.4vw;margin-right:0.5vw;"
                />
                <span>电话:{{i.tel}}</span>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <img src="../../assets/image/liuyan.png" alt="" />
        <span
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.8vw;
            color: #fff;
          "
          >在线留言</span
        >
      </div>
      <div class="message liuyan">
        <input type="text" placeholder="请输入姓名" v-model="name" />
        <input type="text" placeholder="请输入电话" v-model="phone" />
        <input type="text" placeholder="请输入邮箱" v-model="email" />
        <textarea
          rows="5"
          placeholder="请输入留言内容"
          v-model="message"
        ></textarea>
        <div @click="submitMsg">发送信息</div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bannerMixin from "../../mixin/bannerMixin";
export default {
  components: { tapbar, bottombar, moduletitle },
  mixins: [bannerMixin],
  data() {
    return {
      isTop: false,
      indexA: 0,
      indexA02: 0,
      name: "",
      phone: "",
      email: "",
      message: "",
      sort: "4",
      links: [],
      // 各地办事处联系方式
      addressList:[
        {
          title:"公司总部",
          children:[
            {
              address:"江苏省南京市江宁区东山街道工业集中区润发路11号",
              tel:"025-52802986"
            }
          ]
        },
        {
          title:"北京代表处",
          children:[
            {
              address:"北京市西城区广外南街 ",
              tel:""
            }
          ]
        },
        {
          title:"河南大区",
          children:[
            {
              address:"河南省郑州市金水区英协路新鑫花园2号楼 ",
              tel:""
            }
          ]
        },
        {
          title:"上海大区",
          children:[
            {
              address:"上海市长宁区 虹井路888弄",
              tel:""
            }
          ]
        },
        {
          title:"江苏大区",
          children:[
            {
              address:"江苏省南京市江宁区东山街道工业集中区润发路11号",
              tel:""
            }
          ]
        },
        {
          title:"安徽大区",
          children:[
            {
              address:"安徽省合肥市瑶海区临泉路香格里拉花园二期",
              tel:""
            }
          ]
        },
        {
          title:"浙江大区",
          children:[
            {
              address:"杭州市江干区新风路318号红街公寓",
              tel:""
            }
          ]
        },
        {
          title:"湘鄂大区",
          children:[
            {
              address:"武汉市江汉区长青路159号万科城",
              tel:""
            }
          ]
        },
        {
          title:"河北大区",
          children:[
            {
              address:"河北省石家庄市长安区广安大街43号",
              tel:""
            }
          ]
        },
        {
          title:"山东大区",
          children:[
            {
              address:"山东省济南市市中区四里村156号街道万科悦峯",
              tel:""
            }
          ]
        },
        {
          title:"闽赣大区",
          children:[
            {
              address:"江西省南昌市青山湖区北京东路2089号桃源名居",
              tel:""
            },
            {
              address:"福建省福州市台江区宁化街道上浦路富力中心",
              tel:""
            },
          ]
        },
        {
          title:"黑吉辽大区",
          children:[
            {
              address:"辽宁省沈阳市和平区长白二街",
              tel:""
            }
          ]
        },
        {
          title:"秦晋大区",
          children:[
            {
              address:"陕西省西安市碑林区仁厚庄南路仁厚庄园3号楼",
              tel:""
            },
            {
              address:"太原市小店区学府街270号中辐院4号楼",
              tel:""
            },
          ]
        },
        {
          title:"青甘宁大区",
          children:[
            {
              address:"甘肃省兰州市城关区牟家庄达祥小区174号楼",
              tel:""
            },
            {
              address:"青海省西宁市昆仑中路83号国际村公寓2号楼",
              tel:""
            },
          ]
        },
        {
          title:"新疆大区",
          children:[
            {
              address:"新疆乌市沙区阿勒泰路36号汇珊小区",
              tel:""
            }
          ]
        },
        {
          title:"粤琼大区",
          children:[
            {
              address:"广东省广州市天河区潭村路412号",
              tel:""
            }
          ]
        },
        {
          title:"川渝藏大区",
          children:[
            {
              address:"四川省成都市成华区御风二路9号",
              tel:""
            },
            {
              address:"重庆市北部新区恒康路9号",
              tel:""
            },
          ]
        },
        {
          title:"广西大区",
          children:[
            {
              address:"广西省南宁市青秀区古城路6-4号空军后勤部集资楼4栋",
              tel:""
            }
          ]
        },
        {
          title:"云贵大区",
          children:[
            {
              address:"贵阳市云岩区贵乌中路127号博泰华庭1单元",
              tel:""
            },
            {
              address:"云南省昆明市官渡区民航路格林威治2幢1单元",
              tel:""
            },
          ]
        },
      ]
    };
  },
  mounted() {
    this.getLinks();
    this.initMap();
    let that = this;
    window.onscroll = function() {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
  methods: {
    setName(index) {
      if (index == 1) {
        return "软件子公司";
      } else if (index == 2) {
        return "市场部";
      } else {
        return "产品购买及咨询";
      }
    },
    setName02(index) {
      if (index == 1) {
        return "商业合作";
      } else if (index == 2) {
        return "售后服务";
      } else {
        return "投诉及反馈";
      }
    },
    //提交留言
    async submitMsg() {
      if (!this.name) {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
        return;
      }
      if (!this.phone) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      }
      if (!this.email) {
        this.$message({
          message: "请输入电子邮箱",
          type: "warning",
        });
        return;
      }
      if (!this.message) {
        this.$message({
          message: "请输入留言内容",
          type: "warning",
        });
        return;
      }
      const data = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        message: this.message,
      };
      const result = await this.$axios.post("/Message/api/saveMes.do", data);
      if (result.data.code === 0) {
        this.$notify({
          title: "成功",
          message: "提交成功",
          type: "success",
        });
        this.name = "";
        this.phone = "";
        this.email = "";
        this.message = "";
      }
    },
    initMap() {
      var map = new window.BMapGL.Map("container");
      //设置地图显示的中心地址的经纬度(当前是北京)
      var point = new window.BMapGL.Point(118.876891, 31.996506);
      //展示地图(参数二是地图的显示级别)
      map.centerAndZoom(point, 12);
      //允许滚轮缩放大小
      map.enableScrollWheelZoom(true);
      var marker = new window.BMapGL.Marker(point); // 创建标注
      map.addOverlay(marker);
      const infoWindow = this.getInfoWindow();
      map.openInfoWindow(infoWindow, map.getCenter()); // 打开信息窗口
      marker.addEventListener("click", () => {
        map.openInfoWindow(infoWindow, point); // 打开信息窗口
      });
    },
    getInfoWindow() {
      var opts = {
        width: 250, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: "南京华脉科技股份有限公司", // 信息窗口标题
      };
      var infoWindow = new window.BMapGL.InfoWindow(
        "江苏省南京市江宁区东山国际企业研发园润发路11号",
        opts
      ); // 创建信息窗口对象
      return infoWindow;
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "联系我们",
          url: "/contactUs",
          query: {},
        },
      ];
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 30px;
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.cardList {
  margin-top: 3vw;
  cursor: pointer;
}
.card {
  width: 32%;
  background: #a3bed2;
  display: inline-block;
}
.card01 {
  margin-left: 2%;
}
.card01:first-child {
  margin-left: 0;
}
.card02:nth-child(2n) {
  margin: 0 2%;
}
.card > div:first-child {
  background: #f6f6f6;
  height: 8vw;
  width: 100%;
  transition: all 1s;
}
.card > div:last-child {
  background: #0e538a;
  height: 8vw;
  width: 100%;
}
.cardBottom > div {
  font-size: 1vw;
  color: #f8fafc;
  padding-top: 0.5vw;
  padding-left: 2vw;
}
.cardBottom > div:first-child {
  padding-top: 1vw;
}
.cardBottom > div > span {
  margin-left: 1vw;
}

.map {
  width: 100%;
  height: 30vw;
  margin: 3vw 0;
  /* background: rgba(18,75,153,.8); */
}
.mapLeft {
  position: absolute;
  height: 30vw;
  left: 0;
  width: 100%;
  background: #f8f7f4;
}
.mapRight {
  position: absolute;
  height: 30vw;
  right: 0;
  width: 28%;
  background: rgba(18, 75, 153, 0.8);
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 4%;
  box-sizing: border-box;
}
.mapRight1 {
  padding-bottom: 1.6vw;
  border-bottom: 1px solid #fff;
}
.mapRight2 {
  padding: 1.6vw 0;
  border-bottom: 1px solid #fff;
}
.mapRight2-item {
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.mapRight2-item > img {
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 0.6vw;
}
.mapRight3 {
  padding-bottom: 1.6vw;
  margin-top: 1.6vw;
}
.liuyan {
  margin-top: 2vw;
}
.liuyan > input {
  height: 2.5vw;
  border: 1px solid #aaa;
  width: 33%;
  box-sizing: border-box;
  padding-left: 2%;
  font-size: 0.8vw;
}
.liuyan > input:nth-child(2) {
  margin: 0 0.5%;
}
.liuyan > textarea {
  width: 100%;
  height: 10vw;
  box-sizing: border-box;
  border: 1px solid #aaa;
  margin-top: 1vw;
  outline: none;
  padding: 1vw;
  font-size: 0.8vw;
}
.liuyan > div {
  width: 10vw;
  background: #124b99;
  color: #fff;
  line-height: 2vw;
  text-align: center;
  height: 2vw;
  margin: 0 auto;
  margin-top: 1vw;
}
.office {
  width: 65vw;
  min-width: 960px;
  margin: 3vw auto;
}
.office > .title {
  text-align: center;
  font-size: 36px;
  color: rgb(8, 56, 117);
  font-weight: bold;
  position: relative;
  padding-bottom: 0.5vw;
}
.office > .title::after {
  content: "";
  display: inline-block;
  width: 10vw;
  height: 0.3vw;
  background-color: rgb(8, 56, 117);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.office-block{
  margin-top: 2vw;
}
.office-item {
  background-color: #f6f6f6;
  display: inline-block;
  width: 24%;
  height:15vw;
  box-sizing: border-box;
  padding-bottom: 1vw;
  margin-right:1%;
  margin-bottom: 1vw;
  vertical-align: top;
}
.office-item:hover .title {
  background: url("./../../assets/image/contact/contactPlaceHover.png")
    no-repeat center/cover;
  color: #ffffff;
}
.office-block .office-item .title {
  margin-bottom: 1vw;
  padding: 2vw 1vw;
  box-sizing: border-box;
}
.office-block .office-item .title span {
  display: inline-block;
  border-bottom: 1px solid #efecec;
  padding: 0 3vw 0 0;
  box-sizing: border-box;
}
.contactWay {
  color: #7f7f7f;
  padding: 0 1vw;
}
.contactWay>div{
  display: flex;
  font-size: 0.8vw;
  margin-bottom: 0.5vw;
}
</style>
