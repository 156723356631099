<template>
  <div class="home" v-loading="loading">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/banner/1.png')"
        :title="''"
        :links="links"
      ></moduletitle>
      <bar :indexA="productIndex" :productList="barList"></bar>
      <div class="message">
        <div class="introduce">
          <div class="introduceLeft">
            <img
              style="width: 100%; height: 100%"
              :src="info.imageName"
              alt=""
            />
          </div>
          <div class="introduceRight">
            <div
              style="
                position: absolute;
                width: 100%;
                height: 10px;
                top: 0;
                left: 0;
                background: #083875;
              "
            ></div>
            <div style="overflow-y: auto; height: 16vw; margin-top: 1vw">
              <p class="introduceTitle">
                <span>{{ info.solutionName }}解决方案</span>
              </p>
              <div style="line-height: 2.2vw" v-html="info.solutionBrief"></div>
            </div>
            <div
              style="
                color: #fff;
                background: #0e538a;
                width: 10vw;
                height: 2vw;
                line-height: 2vw;
                text-align: center;
                margin: 0 auto;
                margin-top: 3vw;
                position: absolute;
                bottom: 0;
                left: 0;
              "
              @click="toUrl('/contactUs')"
            >
              联系我们
              <span
                style="
                  font-size: 0.7vw;
                  padding-left: 0.2vw;
                  vertical-align: top;
                "
                >▶</span
              >
            </div>
          </div>
        </div>

        <div class="hoverList extra">
          <div
            v-for="(item, index) in hoverList"
            :key="index"
            @mouseenter="
              indexA = index;
              typeName = item;
            "
          >
            <div :style="{ background: indexA == index ? '#fff' : '#1E69A6' }">
              <div :style="{ color: indexA == index ? '#124b99' : '#fff' }">
                0{{ index + 1 }}
              </div>
            </div>
            <p
              style="
                margin-top: 0.2vw;
                color: #fff;
                font-weight: 500;
                font-size: 1vw;
              "
            >
              {{ item }}
            </p>
            <div class="line" v-if="index < hoverList.length - 1"></div>
          </div>
        </div>

        <!-- <div
          style="height: 1px; width: 100%; background: #dcdcdc; margin-top: 3vw"
        ></div> -->

        <!-- 客户价值 -->
        <div class="extra" v-if="typeName === '客户价值'">
          <div class="tit">客户价值</div>
          <img src="../../assets/image/hoverList/user-price.png" alt="" />
        </div>
        <!-- 动态加载 -->
        <planAdvantage
          class="infoBlock"
          v-if="typeName === '方案介绍'"
          :solutionDescription="info.solutionDescription"
        ></planAdvantage>
        <applicationScenarios
          class="infoBlock"
          v-if="typeName === '应用场景'"
          :applicationDescription="info.applicationDescription"
        ></applicationScenarios>
        <relatedProducts
          class="infoBlock"
          v-if="typeName === '相关产品'"
          :productDescription="info.productDescription"
          :productList="productList"
          :pid="parentId"
        ></relatedProducts>
        <successfulCases
          class="infoBlock"
          v-if="typeName === '成功案例'"
          :successList="successList"
          :pid="parentId"
        ></successfulCases>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/category.vue";
import planAdvantage from "../../components/productType/planAdvantage.vue";
import applicationScenarios from "../../components/productType/applicationScenarios.vue";
import successfulCases from "../../components/productType/successfulCases.vue";
import relatedProducts from "../../components/productType/relatedProducts.vue";
export default {
  components: {
    tapbar,
    bottombar,
    moduletitle,
    bar,
    planAdvantage,
    applicationScenarios,
    successfulCases,
    relatedProducts,
  },
  data() {
    return {
      loading:false,//加载状态
      isTop: false,
      hoverList: ["客户价值"],
      indexA: 0,
      id: null,
      info: {
        imageName: "",
        solutionName: "",
        solutionBrief: "",
        solutionDescription: "",
        applicationDescription: "",
        productDescription: "",
      },
      //相关产品的产品列表
      productList: [],
      //成功案例
      successList: [],
      barList: [],
      productIndex: 0,
      links: [],
      //当前鼠标悬浮的模块
      typeName: "客户价值",
      parentId: null,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.id = this.$route.query.id;
    // console.log(this.hoverList,"66666666666666666666")
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    this.getList();
    this.getInfo();
  },
  methods: {
    toUrl(url) {
      this.$router.push({ path: url });
    },
    //查询解决方案信息
    async getInfo() {
      // this.hoverList= ["客户价值"];
      const result = await this.$axios.get("/Programme/api/selectById.do", {
        params: { id: this.id },
      });
      if (result.data.code === 0) {
        this.info = result.data.data;
        if (this.info.solutionDescription) {
          this.hoverList.push("方案介绍");
        }
        if (this.info.applicationDescription) {
          this.hoverList.push("应用场景");
        }
        this.getProducts();
      }
    },
    //查询相关产品下的产品列表
    async getProducts() {
      const result = await this.$axios.get(
        "/Programme/api/getProgrammeProduct.do",
        {
          params: { id: this.id },
        }
      );
      if (result.data.code === 0) {
        console.log(result.data.data,"相关产品------------2222")
        this.productList = []
        this.productList = result.data.data;
        if (this.info.productDescription || this.productList.length > 0) {
          let hoverList =  this.hoverList
          // let index = hoverList.indexOf('相关产品')
          // hoverList.splice(index,1)
          hoverList.push("相关产品");
          this.hoverList = hoverList
        }
        this.getSuccessList();
      }
    },
    //查询成功案例
    async getSuccessList() {
      const result = await this.$axios.get(
        "/Programme/api/getProgrammeCase.do",
        {
          params: { id: this.id },
        }
      );
      if (result.data.code === 0) {
        this.successList = result.data.data;
        if (this.successList.length > 0) {
          let hoverList =  this.hoverList
          // let index = hoverList.indexOf('成功案例')
          // hoverList.splice(index,1)
          hoverList.push("成功案例");
          this.hoverList = hoverList
        }
      }
    },
    getList() {
      this.productIndex = parseInt(sessionStorage.getItem("productIndex"));
      sessionStorage.removeItem("productIndex");
      this.$axios
        .get("/Programme/api/selectFirst.do")
        .then((res) => {
          this.barList = res.data.data;
          this.getParent();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查询解决方案的父级
    async getParent() {
      const result = await this.$axios.get(
        "/Programme/api/selectParentById.do",
        {
          params: {
            id: this.id,
          },
        }
      );
      this.parentId = result.data.data.id;
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "产品及解决方案",
          url: "/productCenter",
          query: { pid: result.data.data.id },
        },
        {
          name: result.data.data.solutionName,
          url: "/productDetail",
          query: { id: this.id },
        },
      ];
    },
  },
  watch: {
    $route: {
      handler() {
        window.scrollTo(0, 0);
        this.id = this.$route.query.id;
        this.getInfo();
        this.hoverList = ["客户价值"];
        // this.getProducts();
        // this.getSuccessList();
        this.typeName = this.hoverList[0]
        this.indexA = 0
      },
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  /* padding-bottom: 24px; */
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  /* width: 65vw; */
  min-width: 960px;
  margin: 0 auto;
  position: relative;
}
.message > div:not(.extra) {
  /* padding:0 15vw; */
  width: 65vw;
  margin: 0 auto;
}
.extra {
  background-color: #f5f5f5;
}
.introduce {
  height: 20vw;
  width: 100%;
  margin-top: 3vw !important;
}
.introduceLeft {
  width: 40%;
  height: 20vw;
  /* background: #C9C9C9; */
  float: left;
  /* background: url("../../assets/image/chanpin01/2.png") no-repeat center/cover; */
}
.introduceRight {
  width: 55%;
  height: 20vw;
  /* background: #c9c9c9; */
  float: right;
  overflow-y: auto;
  position: relative;
}
.introduceTitle {
  width: 100%;
  padding: 15px 0;
}
.introduceTitle > span {
  display: inline-block;
  line-height: 2vw;
  font-size: 1.5vw;
  color: #002c6d;
  position: relative;
  font-weight: bold;
}
.introduceTitle > span > i {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #000;
}
.solution {
  margin-top: 2vw;
}
.solution > div {
  display: inline-block;
  width: 32.5%;
  height: 13vw;
  background: #aaaaaa;
  margin-bottom: 0.8vw;
  position: relative;
}
.solution > div:nth-child(3n-1) {
  margin: 0 1.25% 0.8vw 1.25%;
}
.solution > div > p {
  position: absolute;
  line-height: 2vw;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: #fff;
  bottom: 0;
  text-align: center;
}
.cases {
  margin-top: 3vw;
}
.cases > div {
  display: inline-block;
  width: 49%;
  height: 26vw;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
}
.cases > div:nth-child(2n) {
  margin-left: 2%;
}
.casesImg {
  width: 100%;
  height: 14vw;
  background: #0e538a;
}
.hoverList {
  text-align: center;
  margin-top: 4vw;
  background: url(../../assets/image/hoverList/product-back.png) 0 0/100% 100%
    no-repeat;
  padding: 2vw 0;
}
.hoverList > div {
  display: inline-block;
  margin: 0 4vw;
  font-weight: bold;
  position: relative;
}
.hoverList > div > div:first-child {
  width: 3vw;
  height: 3vw;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
  transition: all 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.hoverList > div > div > div {
  transition: all 0.1s;
}
.line {
  width: 8vw;
  height: 1px;
  border-top: 1px dashed #fff;
  position: absolute;
  top: 36%;
  right: -8vw;
}
.infoBlock {
  padding-bottom: 15px !important;
  /* margin-top:2vw; */
}
.extra {
  position: relative;
}
.extra > .tit {
  font-size: 2vw;
  color: #174f9b;
  font-weight: bold;
  text-align: center;
  position: relative;
  margin-bottom: 3vw;
  position: absolute;
  top: 2vw;
  left: 50%;
  transform: translateX(-50%);
}
.extra > .tit::after {
  content: "";
  display: inline-block;
  height: 0.3vw;
  width: 5vw;
  background: #174f9b;
  position: absolute;
  bottom: -0.5vw;
  left: 50%;
  transform: translateX(-50%);
}
.infoBlock >>> .tit {
  font-size: 2vw;
  color: #174f9b;
  font-weight: bold;
  text-align: center;
  position: relative;
  margin-bottom: 3vw;
  margin-top: 2vw;
}
/* .infoBlock:not(.extra){
  padding-top:2vw;
} */
.infoBlock >>> .tit::after {
  content: "";
  display: inline-block;
  height: 0.3vw;
  width: 5vw;
  background: #174f9b;
  position: absolute;
  bottom: -0.5vw;
  left: 50%;
  transform: translateX(-50%);
}
</style>