<template>
  <div class="bar">
    <div class="barTitle">
      <div
        v-for="(item, index) in list"
        :key="index"
        :style="{
          width: countWidth(index),
          color: indexA == index ? '#083875' : '#000000',
          textAlign: countAlign(index),
        }"
        @click="toLink(item.url, index)"
      >
        {{ item.name }}
        <span v-if="list.length != index + 1"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bar",
  props: ["indexA"],
  data() {
    return {
      index: 0,
      list: [
        { name: "公司简介", url: "/companyProfile" },
        // { name: "组织架构", url: "/organizationalStructure" },
        { name: "社会责任", url: "/socialFunctions" },
        { name: "资质荣誉", url: "/honor" },
        { name: "新闻资讯", url: "/newsInfo" },
        { name: "公司视频", url: "/companyVideo" },
      ],
    };
  },
  methods: {
    countWidth(index) {
      let width = 100 / (this.list.length - 1);
      if (index == 0 || index == this.list.length - 1) {
        return width / 2 + "%";
      } else {
        return width + "%";
      }
    },
    countAlign(index) {
      if (index == 0) {
        return "left";
      } else if (index == this.list.length - 1) {
        return "right";
      } else {
        return "center";
      }
    },
    toLink(url, index) {
      if (this.indexA == index) {
        return;
      }
      // this.indexA = index
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
.bar {
  background: #f6f6f6;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e4e4e4;
}
.barTitle {
  width: 65vw;
  min-width: 960px;
  height: 4vw;
  margin: 0 auto;
  position: relative;
}
.barTitle > div {
  float: left;
  /* width: 16.6%; */
  line-height: 60px;
  text-align: center;
  font-size: 15.5px;
  position: relative;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}
.barTitle > div > span {
  display: inline-block;
  height: 1.5vw;
  width: 1px;
  background: #e2e2e2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
</style>