<template>
  <div class="title">
    <img :src="img" alt="" />
    <img v-if="title == '投 资 者 关 系'&&showImg" src="../assets/image/banner/3.png" style="position:absolute;top:0;left:0" alt="" />
    <div v-if="title == '投 资 者 关 系'" class="left" @click="isTimer">
      <img src="../assets/image/fangxiang/left.png" alt="" />
    </div>
    <div class="right" v-if="title == '投 资 者 关 系'" @click="isTimer">
      <img src="../assets/image/fangxiang/right.png" alt="" />
    </div>
    <span v-if="title == '投 资 者 关 系'&&!showImg">{{ title }}</span>
    <div class="subtitle">{{ subtitle || "" }}</div>
    <div class="crumbs" v-if="links && links.length > 0">
      当前位置：
      <span
        class="name"
        v-for="(item, index) in links"
        :key="item.name"
        @click="toUrl(item.url, item.query)"
        >{{ item.name }}
        <span v-if="index !== links.length - 1">-</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "moduleTitle",
  props: ["img", "title", "subtitle", "links"],
  data(){
    return{
      showImg:false
    }
  },
  mounted(){
    this.timer = setInterval(()=>{
      this.showImg = !this.showImg
    },3000)
  },
  methods: {
    toUrl(path, query) {
      this.$router.push({ path, query });
    },
    isTimer(){
      clearInterval(this.timer)
      this.showImg = !this.showImg
      this.timer = setInterval(()=>{
        this.showImg = !this.showImg
      },3000)
    }
  },
};
</script>
<style scoped>
.title {
  width: 100%;
  background: #0c264a;
  font-family: syht;
  position: relative;
  color: #fff;
}
.title > span {
  text-align: center;
  font-size: 3.6vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@font-face {
  font-family: syht;
  /* src: url("../assets/SourceHanSansCN-Heavy.otf"); */
}
@font-face {
  font-family: syht02;
  /* src: url("../assets/SourceHanSansCN-Regular.ttf"); */
}
.crumbs {
  font-size: 0.8vw;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 2vw;
  left: 0;
  line-height: 1.4vw;
  padding: 0.3vw 1vw 0.3vw 0.3vw;
  font-family: syht02;
  border-top-right-radius: 1vw;
  border-bottom-right-radius: 1vw;
}
.name {
  cursor: pointer;
}
.subtitle {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2vw;
}

.left {
  width: 7vw;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
}
.right {
  width: 7vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.left:hover {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
.right:hover {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
.left:hover img {
  width: 100%;
}
.right:hover img {
  width: 100%;
}
.left > img {
  position: absolute;
  top: 50%;
  width: 80%;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.right > img {
  width: 80%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}
</style>