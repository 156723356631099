var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('tapbar',{attrs:{"isTop":_vm.isTop}}),_c('div',{staticClass:"content-wrapper"},[_c('moduletitle',{attrs:{"img":_vm.bannerImage,"title":'产 品 及 解 决 方 案',"links":_vm.links}}),_c('bar',{attrs:{"indexA":_vm.productIndex,"productList":_vm.productList}}),_c('div',{staticClass:"message"},[_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"introduceLeft"},[(_vm.mainLeftImage)?_c('img',{staticStyle:{"height":"20vw"},attrs:{"src":_vm.mainLeftImage}}):_vm._e()]),_c('div',{staticClass:"introduceRight"},[_c('div',{staticStyle:{"position":"absolute","width":"100%","height":"10px","top":"0","left":"0","background":"#083875"}}),_c('div',{staticStyle:{"overflow-y":"auto","height":"16vw","margin-top":"1vw"}},[_c('p',{staticClass:"introduceTitle"},[_c('span',[_vm._v(_vm._s(_vm.details.solutionName))])]),_c('p',{staticStyle:{"line-height":"2.2vw","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.details.solutionBrief)+" ")])]),_c('div',{staticStyle:{"color":"#fff","background":"#0e538a","width":"10vw","height":"2vw","line-height":"2vw","text-align":"center","margin":"0 auto","margin-top":"3vw","position":"absolute","bottom":"0","left":"0"},on:{"click":function($event){return _vm.toUrl('/contactUs')}}},[_vm._v(" 联系我们 "),_c('span',{staticStyle:{"font-size":"0.7vw","padding-left":"0.2vw","vertical-align":"top"}},[_vm._v("▶")])])])]),_c('p',{staticStyle:{"text-align":"center","font-size":"36px","color":"#083875","font-weight":"bold","margin-top":"3vw"}},[_vm._v(" 解决方案 ")]),_c('p',{staticClass:"underline"}),_c('div',{staticClass:"solution"},_vm._l((_vm.details.sonList),function(item,index){return _c('div',{key:index,style:({
            background: 'url(' + item.imageName + ') no-repeat center/cover',
          }),on:{"click":function($event){return _vm.toProductDetail(item.id)},"mouseenter":function($event){return _vm.onEnter(index)},"mouseleave":_vm.onLeave}},[_c('p',[_vm._v(_vm._s(item.solutionName))]),_c('span',{staticClass:"hoverOpt",class:_vm.hoverActive != null
                ? _vm.hoverActive == index
                  ? 'hoverActive'
                  : 'otherActive'
                : ''})])}),0),(_vm.caseList && _vm.caseList.length > 0)?_c('p',{staticStyle:{"text-align":"center","font-size":"36px","color":"#083875","font-weight":"bold","margin-top":"3vw"}},[_vm._v(" 成功案例 ")]):_vm._e(),(_vm.caseList && _vm.caseList.length > 0)?_c('p',{staticClass:"underline"}):_vm._e(),(_vm.caseList && _vm.caseList.length)?_c('div',{staticClass:"cases"},[_c('el-carousel',{staticStyle:{"width":"100%","border":"none","padding-bottom":"30px"}},_vm._l((_vm.caseList),function(item,index){return _c('el-carousel-item',{key:index,staticStyle:{"display":"flex","justify-content":"space-between"}},_vm._l((item),function(i,ind){return _c('div',{key:ind,staticStyle:{"width":"48%","border":"1px solid #dcdcdc"},on:{"click":function($event){return _vm.toUrl(
                  '/successfulCases?id=' + i.id + '&pid=' + _vm.$route.query.pid
                )}}},[_c('div',{staticClass:"casesImg",style:({
                  background: 'url(' + i.imgurl + ') no-repeat center/cover',
                })}),_c('p',{staticStyle:{"font-size":"23px","font-weight":"bold","text-align":"center","padding":"1vw 0"}},[_vm._v(" "+_vm._s(i.title)+" ")]),_c('p',{staticStyle:{"text-align":"left","padding":"0 1vw","display":"-webkit-box","-webkit-box-orient":"vertical","-webkit-line-clamp":"2","overflow":"hidden","font-size":"15px","color":"#666"}},[_vm._v(" "+_vm._s(i.detail)+" ")]),_c('div',{staticStyle:{"text-align":"right","padding-right":"1.4vw","margin-top":"1.3vw"}},[_c('span',{staticStyle:{"display":"inline-block","width":"8vw","height":"2vw","line-height":"2vw","text-align":"center","border":"1px solid #dcdcdc","color":"#0d3c78"}},[_vm._v("了解详情>")])])])}),0)}),1)],1):_vm._e()])],1),_c('bottombar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }