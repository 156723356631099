<template>
  <div class="bottom">
    <div class="bottomDiv">
      <div
        class="bottomBlock"
        v-for="(item, index) in list"
        :key="index"
        @click="toUrl(item)"
      >
        <div>{{ item.name }}</div>
        <p
          v-for="(item02, index02) in item.list"
          :key="index02"
          @click.stop="toUrl(item02)"
        >
          {{ item02.name }}
        </p>
        <!-- <span v-if="index != 0"></span> -->
        <!-- <span></span> -->
      </div>
      <div style="padding-left:2vw!important">
        <div style="width:100px;margin-top:40px;float:left;text-align:center">
          <img style="width:90%"
            src="../assets/image/qrCode.png"
          />
          <div style="font-size:12px;color:#fff;">官方微信公众号</div>
        </div>
        <div style="width:100px;margin-top:40px;float:left;text-align:center;margin-left:20px">
          <img style="width:90%"
            src="../assets/image/qrCode02.png"
          />
          <div style="font-size:12px;color:#fff;">京东自营旗舰店</div>
        </div>
        <div style="display:inline-block;margin-left:1vw;margin-top:2vw;float:left;font-size:14px">
          <div style="margin-top: 5px;display:flex;">
            <span>
              <img
                src="../assets/image/phone.png"
                alt=""
                style="width: 10px; position: relative; top: 4px;padding-right:10px"
              />
              服务热线：
            </span>
            <div>800-828-9565<br />400-025-9565<br />025-52802986</div>
          </div>
          <div style="display:flex;">
            <span>
              <img
                src="../assets/image/phone.png"
                alt=""
                style="width: 10px; position: relative; top: 4px;padding-right:10px"
              />
              投诉电话：
            </span>
            <div>13901595192</div>
          </div>
        </div>
      </div>
    </div>
    <p
      style="
        background: #061d3e;
        font-size: 14px;
        text-align: center;
        color: #fff;
        line-height: 50px;
        position: absolute;
        bottom: 0;
        width: 100%;
      "
    >
      华脉科技股份有限公司 版权所有 <a style="color:#fff!important" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">苏ICP备13005635号-1</a> 
    </p>
  </div>
</template>
<script>
export default {
  name: "bottomBar",
  data() {
    return {
      list: [
        {
          name: "走进华脉",
          url: "/companyProfile",
          list: [
            { name: "公司简介", url: "/companyProfile" },
            // { name: "组织架构", url: "/organizationalStructure" },
            { name: "社会责任", url: "/socialFunctions" },
            { name: "资质荣誉", url: "/honor" },
            { name: "新闻资讯", url: "/newsInfo" },
            { name: "公司视频", url: "/companyVideo" },
          ],
        },
        {
          name: "产品及解决方案",
          url: "/productCenter",
          list: [],
        },
        {
          name: "招贤纳士",
          url: "/recruitment",
          list: [
            { name: "热招岗位", url: "/recruitment" },
            {
              name: "前程无忧",
              url: "https://jobs.51job.com/all/co2257472.html",
            },
            {
              name: "BOSS直聘",
              url:
                "https://www.zhipin.com/gongsi/8ff1b437ceaba4243nV82Ns~.html?ka=search_rcmd_company_name_8ff1b437ceaba4243nV82Ns~_custompage",
            },
            {
              name: "BOSS直聘（华脉软件）",
              url:
                "https://www.zhipin.com/gongsi/44b01e7eef3db0e31nN_3Ni9EFQ~.html?ka=search_rcmd_company_name_44b01e7eef3db0e31nN_3Ni9EFQ~_custompage",
            },
            {
              name: "58同城",
              url:
                "https://qy.58.com/20207371746311/?PGTID=0d302408-000a-c294-8fbd-418ba004ba7d&ClickID=3",
            },
          ],
        },
        {
          name: "联系我们",
          url: "/contactUs",
          list: [
            { name: "产品购买及咨询", url: "/contactUs" },
            { name: "各地办事处", url: "/contactUs" },
            { name: "产业大厦", url: "/merchants" },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    //查询解决方案菜单
    async getProductList() {
      const result = await this.$axios.get("/Programme/api/getSolutionTree.do");
      if (result.data.code === 0) {
        const productList = [];
        result.data.data.forEach((element) => {
          productList.push({
            name: element.solutionName,
            url: "/productCenter",
            query: {
              pid: element.id,
            },
          });
        });
        this.list[1].query = {
          pid: productList[0].query.pid,
        };
        this.list[1].list = productList;
      }
    },
    toUrl(item) {
      if (
        item.name === "BOSS直聘" ||
        item.name === "前程无忧" ||
        item.name === "BOSS直聘（华脉软件）" ||
        item.name === "58同城"
      ) {
        window.open(item.url);
        return;
      }
      if (item.query) {
        this.$router.push({ path: item.url, query: item.query });
      } else {
        this.$router.push({ path: item.url });
      }
    },
  },
};
</script>
<style scoped>
.bottom {
  width: 100%;
  height: 300px;
  background: #213d64 url("../assets/image/bottomBg.png") no-repeat bottom/100%;
  margin-top: 3vw;
  position: fixed;
  bottom: 0;
  left: 0;
}

.bottomDiv {
  width: 90vw;
  min-width: 960px;
  margin: 0 auto;
  text-align: center;
  /* background: #fff; */
  position: relative;
  /* display: flex; */
  /* justify-content: space-around; */
}
.bottomDiv > div {
  text-align: left;
  /* width: 20%; */
  color: #fff;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-right: 3vw;
  padding-left: 3vw;
}
.bottomBlock {
  position: relative;
}
.bottomBlock::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 150px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  right: 0px;
  top: 50px;
}
.bottomDiv > div > div {
  font-size: 17px;
  padding: 10px 0;
  margin-top: 30px;
  color: #a3cdfc;
  cursor: pointer;
}
.bottomDiv > div > span {
  position: absolute;
  width: 0.5px;
  height: 150px;
  background: #999;
  top: 45px;
  left: -3vw;
}
/* .bottomDiv > div:first-child {
  width: 18%;
}
.bottomDiv > div:nth-child(2) {
  width: 22%;
}
.bottomDiv > div:nth-child(3) {
  width: 18%;
}
.bottomDiv > div:nth-child(4) {
  width: 20%;
} */
.bottomDiv > div:last-child {
  /* width: 22% !important; */
  padding-top: 30px;
  display: inline-block;
}
.bottomDiv > div > p {
  font-size: 14px;
  padding: 3px 0;
  color: #ecedee;
  cursor: pointer;
  cursor: pointer;
}
.bottomDiv > div > p:hover {
  color: #fff;
}
</style>
