<template>
  <div class="float" style="background:pink;">
    <div
      @mouseenter="kefu = true"
      @mouseleave="kefu = false"
      :style="{ background: kefu ? '#0E538A' : '#fff' }"
    >
      <img
        v-if="!kefu"
        :src="require('../assets/image/float0A.png')"
        alt=""
      />
      <img
        v-if="kefu"
        :src="require('../assets/image/float0.png')"
        alt=""
      />
      <p :style="{ color: kefu ? '#fff' : '#0E538A' }">客服电话</p>
      <div class="submitPhone" v-if="kefu">
        <div style="font-size:23px;font-weight:bold;color:#0E538A;text-align:center">
          025-52802986
        </div>
        <span>▶</span>
      </div>
    </div>

    <div
      @mouseenter="submitPhone = true"
      @mouseleave="submitPhone = false"
      :style="{ background: submitPhone ? '#0E538A' : '#fff' }"
    >
      <img
        v-if="!submitPhone"
        :src="require('../assets/image/float1A.png')"
        alt=""
      />
      <img
        v-if="submitPhone"
        :src="require('../assets/image/float1.png')"
        alt=""
      />
      <p :style="{ color: submitPhone ? '#fff' : '#0E538A' }">在线留言</p>
      <div class="submitPhone" v-if="submitPhone">
        <div>
          <input type="text" placeholder="请输入姓名" v-model="name" />
          <input type="text" placeholder="请输入电话" v-model="mobile" />
          <input type="text" placeholder="请输入邮箱" v-model="email" />
          <textarea
            placeholder="请输入描述"
            rows="5"
            v-model="message"
          ></textarea>
          <div class="btns">
            <!-- <span @click="submitPhone = false">取消发送</span> -->
            <span @click="send()">发送消息</span>
          </div>
        </div>
        <span>▶</span>
      </div>
    </div>
    <div
      @mouseenter="submitCard = true"
      @mouseleave="submitCard = false"
      :style="{ background: submitCard ? '#0E538A' : '#fff' }"
    >
      <img
        v-if="!submitCard"
        :src="require('../assets/image/float2A.png')"
        alt=""
      />
      <img
        v-if="submitCard"
        :src="require('../assets/image/float2.png')"
        alt=""
      />
      <p :style="{ color: submitCard ? '#fff' : '#0E538A' }">关注微信号</p>

      <div
        class="submitPhone"
        style="width: 10vw; left: -10vw"
        v-if="submitCard"
      >
        <div style="width: 9.3vw">
          <img src="../assets/image/qrCode.png" alt="" style="width: 90%" />
          <p style="font-size: 0.8vw; color: #333">南京华脉科技有限公司</p>
        </div>
        <span>▶</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      submitPhone: false,
      submitCard: false,
      kefu:false,
      name: "",
      mobile: "",
      email: "",
      message: "",
    };
  },
  methods: {
    send() {
      if (!this.mobile && !this.email) {
        alert("请至少留下您的电话或者邮箱");
        return;
      }
      this.$axios
        .post("/Message/api/saveMes.do", {
          name: this.name,
          phone: this.mobile,
          email: this.email,
          message: this.message,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            alert("提交成功");
            this.name = "";
            this.mobile = "";
            this.email = "";
            this.message = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.float {
  width: 5vw;
  height: 10vw;
  background: #fff;
  position: fixed;
  right: 0;
  bottom: 50vh;
  border: 1px solid #eee;
  /* box-shadow: 5px 2px 2px 1px #ddd; */
  z-index: 999;
}
.float > div {
  width: 5vw;
  height: 5vw;
  background: #fff;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.float > div > img {
  width: 40%;
  margin-top: 1vw;
}
.float > div > p {
  font-size: 0.6vw;
  margin-top: 0.5vw;
  color: #0e538a;
}
.submitPhone {
  width: 18.5vw;
  /* height: 20vw; */
  position: absolute;
  left: -18.5vw;
  top: 50%;
  transform: translateY(-50%);
}
.submitPhone > span {
  color: #fff;
  position: absolute;
  right: -0.3vw;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4vw;
}
.submitPhone > div {
  background: #fff;
  width: 18vw;
  box-shadow: 0px 1px 1px 1px #eee;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
.submitPhone > div > input,
.submitPhone > div > textarea {
  width: 80%;
  display: inline-block;
  background: #fafafa;
  border: 1px solid #dcdcdc;
  line-height: 2vw;
  padding-left: 1vw;
  margin-top: 0.4vw;
  font-size: 15px;
}
.btns{
  width: 86%;
  margin-left: 1.2vw;
}
.btns>span{
  display: inline-block;
  background: #0e538a;
  color: #fff;
  font-size: 0.8vw;
  line-height: 2vw;
  text-align: center;
  width: 100%;
}
/* .btns>span:first-child{
  margin-right: 4%;
  background: #757575;
} */
</style>