<template>
  <div class="home">
    <div class="homeDiv">
      <!-- 头部 -->
      <tapBar :isTop="isTop"></tapBar>
      <!-- 轮播 -->
      <div style="position: relative; height: 41vw;overflow-y: hidden;" :style="{background:'#eee url(' + require('../assets/image/logo01.png') + ') no-repeat center/400px'}">
        <div v-for="(item, index) in banners" :key="'banner_' + item.id">
          <img :src="item.banner_image" v-if="lunboTabA == index" />
        </div>
        <div class="lunboTab">
          <div :class="{ lunboTabA: lunboTabA == index }" v-for="(item, index) in banners" :key="item.id"></div>
        </div>
        <div class="left" @click="changeToLeft">
          <img src="../assets/image/fangxiang/left.png" alt="" />
        </div>
        <div class="right" @click="changeToRight">
          <img src="../assets/image/fangxiang/right.png" alt="" />
        </div>
      </div>
      <!-- 标题 -->
      <div :class="{ title: true, dong01: dong }">
        <p class="title02">新 闻 中 心</p>
        <p class="underline"></p>
      </div>

      <div class="banner02" style="background: none">
        <div v-if="img[indexA]" :class="{ lunbo: true, dong02: dong }" :style="{background:'url(' + img[indexA].imgurl + ') no-repeat center/cover'}" @click="toNewsDetail(img[indexA].id)">
          <div class="lunboDiv" @click="toNewsDetail(img[indexA].id)">
            <div class="lunboDiv01">
              <div style="font-size: 1.8vw; font-weight: bold">
                {{ img[indexA].date }}
              </div>
              <div style="height: 2px;background: #fff;width: 4vw;margin: 0 auto;"></div>
              <div style="font-size: 1vw">
                {{ img[indexA].year }}-{{ img[indexA].month }}
              </div>
            </div>

            <div class="lunboDiv02">{{ img[indexA].title }}</div>
            <div class="lunboDiv03">
              <div v-for="(item, index) in img" :style="{background:index == indexA? 'rgba(255,255,255,1)': 'rgba(255,255,255,.5)'}" :key="index"></div>
            </div>
          </div>
        </div>

        <div :class="{ xinwen: true, dong03: dong }">
          <div class="xinwenDiv01">
            <div v-for="(item, index) in xinwen" :class="{ xinwenA: xinwenA == index }" @click="chooseNewsType(index)" :key="index">
              {{ item }}
              <div v-if="index != 0 && xinwenA != index + 1"></div>
            </div>
          </div>
          <div class="xinwenDiv02">
            <vue-seamless-scroll :data="newsList" class="seamless-warp" :class-option="defaultOption">
              <div v-for="(item, index) in newsList" :key="index" @click="toNewsDetail(item.id)">
                • {{ item.title }}
                <span>{{ item.createtime }}</span>
              </div>
            </vue-seamless-scroll>
            <p @click="toNews">
              <span style="margin-right: 1vw"></span>更&emsp;多<span style="margin-left: 1vw"></span>
            </p>
          </div>
        </div>
      </div>

      <!-- 标题 -->
      <div :class="{ title: true, dong01: dong01 }">
        <p class="title02">产 品 及 解 决 方 案</p>
        <p class="underline"></p>
      </div>

      <div class="banner02" style="background: none; width: 100% !important">
        <div class="canpinLeft">
          <div :class="{ canpinLeft01: true, dong02: dong01 }" @click="toProductCenter(0)">
            <img src="../assets/image/chanpin/1.png" alt="" style="opacity: 0"/>
            <img class="fontHoverBefore" src="../assets/image/chanpin/font/tongxin.png" alt="" style="position: absolute; bottom: 3vw; left: 4vw; width: 7vw"/>
          </div>
          <div :class="{ canpinLeft02: true, dong03: dong01 }" @click="toProductCenter(2)">
            <img src="../assets/image/chanpin/2.png" alt="" style="opacity: 0"/>
            <img class="fontHoverBefore" src="../assets/image/chanpin/font/jccxxm.png" alt="" style="position: absolute; bottom: 3vw; left: 4vw; width: 12vw"/>
          </div>
        </div>
        <div class="canpinRight">
          <div :class="{ canpinRight01: true, dong04: dong01 }" @click="toProductCenter(1)">
            <img src="../assets/image/chanpin/5.gif" alt="" style="opacity: 0"/>
            <img class="fontHoverBefore" src="../assets/image/chanpin/font/dataCenter.png" alt="" style="position: absolute; bottom: 3vw; left: 4vw; width: 7vw"/>
          </div>
          <div style="position: relative">
            <div class="canpinRight02" style="width: 49%; display: inline-block; position: relative" @click="toProductCenter(3)">
              <img src="../assets/image/chanpin/3.png" alt="" style="opacity: 0"/>
              <img src="../assets/image/chanpin/font/xxzzfw.png" alt="" style="position: absolute; bottom: 3vw; left: 4vw; width: 10vw"/>
            </div>
            <div class="canpinRight03" style="display: inline-block;width: 49%;margin-left: 2%;vertical-align: top;position: relative;" @click="toProductCenter(0)">
              <img src="../assets/image/chanpin/4.png" alt="" style="opacity: 0"/>
              <img src="../assets/image/chanpin/font/more.png" style="position: absolute; bottom: 3vw; left: 4vw; width: 7vw"/>
            </div>
          </div>
        </div>
      </div>

      <!-- 标题 -->
      <div :class="{ title: true, dong01: dong02 }">
        <p class="title02">最 新 推 荐</p>
        <p class="underline"></p>
      </div>


      <div class="banner02 hezuoList" style="background: none;width:99vw;overflow-x: hidden;" @click="toRecommend($event)">
        <vue-seamless-scroll :data="recommendList" :class-option="defaultOption02">
          <div class="goods" v-for="(item, index) in recommendList" :class="dong02 ? 'dong0' + (index + 1) : ''" :key="index">
            <div :data-item="JSON.stringify(item)"></div>
            <p style="color: #363636;font-size: 1.2vw;font-weight: bold;margin-top: 1.3vw;" :data-item="JSON.stringify(item)">
              {{ item.name }}
            </p>
            <p style="color: #878787;font-size: 0.8vw;width: 13vw;margin: 0 auto;" class="ellipsis_p" :data-item="JSON.stringify(item)">
              {{ item.secTit }}
            </p>
          </div>
        </vue-seamless-scroll>
      </div>

      <!-- 标题 -->
      <div :class="{ title: true, dong01: dong03 }">
        <p class="title02">成 功 案 例</p>
        <p class="underline"></p>
      </div>

      <div class="banner02" style="background: none">
        <div :class="{ anli: true, dong06: dong03 }">
          <div
            v-for="(item, index) in caseList"
            :key="'img' + item.id"
            class="anliImg"
          >
            <img
              v-if="lunboTab02A == index"
              :src="item.imgurl"
              alt=""
              style="cursor: pointer"
              @click="toSuccessDetail(item.id)"
            />
          </div>
        </div>
        <div :class="{ anliRight: true, dong07: dong03 }">
          <p
            :style="{
              fontSize: '1.2vw',
              color: '#333333',
              paddingTop: lunboTab02A == index ? '2vw' : '',
              paddingLeft: lunboTab02A == index ? '2vw' : '',
            }"
            v-for="(item, index) in caseList"
            :key="'title' + item.id"
          >
            <span v-if="lunboTab02A == index">{{ item.title }}</span>
          </p>
          <div
            style="
              height: 2px;
              background: #858585;
              width: 2.5vw;
              margin-left: 2.2vw;
              margin-top: 1.5vw;
            "
          ></div>
          <div v-for="(item, index) in caseList" :key="'detail' + item.id">
            <div
              style="text-align: justify; padding: 1vw 2vw; font-size: 0.8vw"
              v-if="lunboTab02A == index"
            >
              {{ item.detail }}
            </div>
          </div>
          <div v-for="(item, index) in caseList" :key="'toDetail' + item.id">
            <div
              style="
                background: #ff6600;
                width: 5vw;
                height: 2vw;
                line-height: 2vw;
                color: #fff;
                text-align: center;
                font-size: 0.8vw;
                margin-left: 2vw;
                cursor: pointer;
                position: absolute;
                left: 0;
                bottom: 4%;
              "
              v-if="lunboTab02A == index"
              @click="toSuccessDetail(item.id)"
            >
              查看详情
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative" class="lunboTab02">
        <!-- <div :class="{ lunboTabA02: lunboTab02A == 0 }"></div>
        <div :class="{ lunboTabA02: lunboTab02A == 1 }"></div> -->
        <div
          :class="{
            lunboTabA02: lunboTab02A == index,
            'lunboTabA02-wrapper': true,
          }"
          v-for="(item, index) in caseList"
          :key="'toDetailInd' + item.id"
          @click="handlerCaseTabClick(index)"
        ></div>
      </div>

      <!-- 标题 -->
      <div :class="{ title: true, dong01: dong04 }">
        <p class="title02">合 作 企 业</p>
        <p class="underline"></p>
      </div>

      <div class="banner02 hezuoList" style="background: none">
        <vue-seamless-scroll
          :data="hezuo"
          class="seamless-warp"
          :class-option="defaultOption02"
        >
          <div
            :class="{ hezuo: true, dong05: dong04 }"
            v-for="(item, index) in hezuo"
            :key="index"
            :style="{ background: 'url(' + item + ') no-repeat center/100%' }"
          ></div>
        </vue-seamless-scroll>
      </div>

      <!-- <div
        style="position: absolute; height: 280px; bottom: -280px; width: 100%"
      ></div> -->
    </div>
    <bottomBar></bottomBar>
  </div>
</template>
<script>
import tapBar from "../components/top.vue";
import bottomBar from "../components/bottom.vue";
export default {
  components: {
    tapBar,
    bottomBar,
  },
  data() {
    return {
      isTop: false, //滚动条是否居于顶部。
      xinwenA: 0,
      lunboTabA: 0,
      lunboTab02A: 0,
      search: false,
      xinwen: ["公司新闻", "行业动态", "展会信息", "员工活动"],
      defaultOption01: {
        step: 2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      defaultOption02: {
        step: 2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      listData: [
        {
          title: "超越自我 促进职业技能提升",
          date: "2017-12-16",
        },
        {
          title: "邀请函 | 南京华脉科技与您共享2021年中国国际广播电视信息...",
          date: "2017-12-16",
        },
        {
          title: "省服务外包协会到访华脉信息产业集团",
          date: "2017-12-16",
        },
        {
          title: "南京华脉科技与您携手2021年中国国际广播电视信息网络展览...",
          date: "2017-12-16",
        },
        {
          title: "邀请函 | 南京华脉科技与您相约2021年中国国际广播电视信息...",
          date: "2017-12-16",
        },
        {
          title: " “企业管理提升专项行动”走进华脉，让华脉优秀管理创新经验...",
          date: "2017-12-16",
        },
        {
          title: "省服务外包协会到访华脉信息产业集团",
          date: "2017-12-16",
        },
      ],
      // 推荐产品
      recommendList: [
        {
          name: "特殊场景手机管控",
          secTit:
            "随着移动通信技术飞速发展，手机日益成为人们工作和生活中不可缺少的重要工具。现代化通信手段给我们创造了方便、快捷、准确的综合性服务的同时，也带来了日益严重的信息安全隐患。",
          url: "/productDetail?id=54",
        },
        {
          name: "微模块数据中心基础设施",
          secTit:
            "为了适应数据中心高速发展，满足数据中心节能环保、高功率密度、快速建设等需求，华脉推出了微模块数据中心解决方案",
          url: "/productDetail?id=41",
        },
        {
          name: "光缆监测",
          secTit:
            "随着光纤通信快速发展的需要，光通信对光纤网络稳定性的要求，光缆维护与管理的问题因此日渐突出，严重影响到通信网的正常工作，对光缆的日常巡查也缺乏良好的监督。如何才能维护和管理好光缆网络资源，预警光缆故障，精确定位故障点是当前光缆维护管理工作的亟待解决的问题。南京华脉科技有限公司基于行业现状，以线路故障维护、隐患分析、资源管理为基础推出了RFTS型光缆监测系统。",
          url: "/productDetail?id=38",
        },
        {
          name: "POI无线覆盖",
          secTit:
            "多系统合路平台POI可依据不同的使用场景定制化，可满足单运营商2G、3G、4G、5G,也可以满足多运营商的共建共享，也可以在专网如公安、集群800M、铁路PIS、800M政务网、首信1.4G等系统信号覆盖中得到广泛应用。",
          url: "/productDetail?id=45",
        },
        {
          name: "无线WIFI",
          secTit:
            "无线Wi-Fi网络越来越多的承载客户的业务，提供更加丰富和流畅的网络服务。在室内和室外场景、普通和高密场景、简单和复杂的场景用户都需要无线Wi-Fi覆盖满足移动上网的需求。在新的Wi-Fi6网络建设趋势下，大流量、高并发、低时延的移动应用会更加依赖于无线网络。",
          url: "/productDetail?id=37",
        },
      ],
      dong: false,
      dong01: false,
      dong02: false,
      dong03: false,
      dong04: false,
      pageIndex: 1,
      left: false,
      right: false,
      dakaiIndex: null,
      zoujin: false,
      chanpin: false,
      ceshi: false,
      indexA: 0,
      img: [],
      topList: [
        {
          name: "走进华脉",
        },
        {
          name: "产品及解决方案中心",
        },
        {
          name: "投资者关系",
        },
        {
          name: "联系我们",
        },
        {
          name: "招贤纳士",
        },
      ],
      hezuo: [
        require("../assets/image/hezuo/1.png"),
        require("../assets/image/hezuo/2.png"),
        require("../assets/image/hezuo/3.png"),
        require("../assets/image/hezuo/4.png"),
        require("../assets/image/hezuo/5.png"),
        require("../assets/image/hezuo/6.png"),
        require("../assets/image/hezuo/7.png"),
        require("../assets/image/hezuo/9.png"),
        require("../assets/image/hezuo/10.png"),
      ],
      banners:[],
      // banners: [
      //   {
      //     id: "banner_1",
      //     banner_image: require("../assets/image/banner/banner2.png"),
      //   },
      //   {
      //     id: "banner_6",
      //     banner_image: require("../assets/image/banner/banner7.png"),
      //   },
      //   {
      //     id: "banner_2",
      //     banner_image: require("../assets/image/banner/banner3.jpg"),
      //   },
      //   {
      //     id: "banner_3",
      //     banner_image: require("../assets/image/banner/banner4.jpg"),
      //   },
      //   {
      //     id: "banner_4",
      //     banner_image: require("../assets/image/banner/banner5.jpg"),
      //   },
      //   {
      //     id: "banner_5",
      //     banner_image: require("../assets/image/banner/banner6.jpg"),
      //   },
        
      // ],
      bannerTime: null,
      newsList: [],
      newsType: 1,
      caseList: [],
      productList: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.getNewsBanner();
    this.getBanner();
    this.getNesList();
    this.setTime();
    this.getList();
    this.time = setInterval(() => {
      this.lunboTab02A = this.lunboTab02A + 1;
      if (this.lunboTab02A == this.caseList.length) {
        this.lunboTab02A = 0;
      }
    }, 3000);
    let that = this;
    that.dong = true;
    window.onscroll = function() {
      //获取距离页面顶部的距离
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
      // let haha0 = document.getElementsByClassName("title")[0]
      let haha = document.getElementsByClassName("title")[1];
      let haha02 = document.getElementsByClassName("title")[2];
      let haha03 = document.getElementsByClassName("title")[3];
      let haha04 = document.getElementsByClassName("title")[4];

      if (
        toTop >
        haha.offsetTop - window.innerHeight + window.innerHeight / 4
      ) {
        that.dong01 = true;
      }
      if (
        toTop >
        haha02.offsetTop - window.innerHeight + window.innerHeight / 4
      ) {
        that.dong02 = true;
      }
      if (
        toTop >
        haha03.offsetTop - window.innerHeight + window.innerHeight / 4
      ) {
        that.dong03 = true;
      }
      if (
        toTop >
        haha04.offsetTop - window.innerHeight + window.innerHeight / 4
      ) {
        that.dong04 = true;
      }
    };
    this.getSuccessCase();
  },
  methods: {
    setTime() {
      this.bannerTime = setInterval(() => {
        if (this.lunboTabA === this.banners.length - 1) {
          this.lunboTabA = 0;
        } else {
          this.lunboTabA = this.lunboTabA + 1;
        }
      }, 8000);
    },
    gundong() {
      setInterval(() => {
        if (this.indexA === this.img.length - 1) {
          this.indexA = 0;
        } else {
          this.indexA = this.indexA + 1;
        }
      }, 8000);
    },
    dakai(index) {
      if (index == 0) {
        this.zoujin = true;
        this.chanpin = false;
      } else if (index == 1) {
        this.zoujin = false;
        this.chanpin = true;
      } else {
        this.zoujin = false;
        this.chanpin = false;
      }
    },
    guanbi() {
      this.zoujin = false;
      this.chanpin = false;
    },
    changeToLeft() {
      clearInterval(this.bannerTime);
      if (this.lunboTabA === 0) {
        this.lunboTabA = this.banners.length - 1;
      } else {
        this.lunboTabA = this.lunboTabA - 1;
      }
      this.setTime();
    },
    changeToRight() {
      clearInterval(this.bannerTime);
      if (this.lunboTabA === this.banners.length - 1) {
        this.lunboTabA = 0;
      } else {
        this.lunboTabA = this.lunboTabA + 1;
      }
      this.setTime();
    },
    //查询轮播图
    async getBanner() {
      const result = await this.$axios.get("/Banner/api/getIndexBanner.do");
      if (result.data.code === 0) {
        this.banners = result.data.data;
      }
    },
    //查询新闻轮播图
    async getNewsBanner() {
      const result = await this.$axios.get("/News/api/getNewList.do", {
        params: {
          page: 1,
          limit: 5,
        },
      });
      if (result.data.code === 0) {
        const list = result.data.data;
        this.img = list.map((item) => {
          const createtime = item.createtime.split(" ")[0];
          item.createtime = createtime;
          item.year = createtime.split("-")[0];
          item.month = createtime.split("-")[1];
          item.date = createtime.split("-")[2];
          return item;
        });
        this.gundong();
      }
    },
    //查询新闻列表
    async getNesList() {
      const result = await this.$axios.get("/News/api/getNewList.do", {
        params: {
          type: this.newsType,
          page: 1,
          limit: 999,
        },
      });
      if (result.data.code === 0) {
        const list = result.data.data;
        this.newsList = list.map((item) => {
          item.createtime = item.createtime.split(" ")[0];
          return item;
        });
      }
    },
    chooseNewsType(index) {
      this.xinwenA = index;
      this.newsType = index + 1;
      this.getNesList();
    },
    toNews() {
      this.$router.push({ path: "/newsInfo" });
    },
    //查询成功案例
    async getSuccessCase() {
      const result = await this.$axios.get("/Case/api/selectIndex.do");
      if (result.data.code === 0) {
        this.caseList = result.data.data;
      }
    },
    toSuccessDetail(id) {
      this.$router.push({ path: "successfulCases", query: { id } });
    },
    toNewsDetail(id) {
      this.$router.push({ path: "/newsInfoDetails", query: { params:JSON.stringify({currentId:id})} });
    },
    // 获取列表
    getList() {
      this.$axios.get("/Programme/api/selectFirst.do").then((res) => {
        this.productList = res.data.data;
      });
    },
    toProductCenter(index) {
      this.$router.push({
        path: "/productCenter",
        query: { pid: this.productList[index].id },
      });
    },
    // 进入推荐产品页面
    toRecommend(e) {
      let target = e.target;
      console.log(target)
      localStorage.setItem("topBarA", JSON.parse(target.dataset.item).url);
      this.$router.push(JSON.parse(target.dataset.item).url);
      // if(target.tagName == "DIV") {
      //   console.log(target)
      //   
      // }
    },
    handlerCaseTabClick(index) {
      this.lunboTab02A = index;
    },
  },
  computed: {
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
<style scoped>
.home {
  width: 100%;
  min-width: 960px;
  min-height: 100%;
  /* background: url('../assets/image/banner.png') no-repeat top/100%; */
  background: #f6f6f6;
}
.homeDiv {
  position: relative;
  z-index: 2;
  background: #f6f6f6;
  /* left: 50%; */
  width: 100%;
  /* transform: translateX(-50%); */
  margin-bottom: 280px;
}
.banner02 {
  width: 65vw;
  min-width: 960px;
  margin: 0 auto;
  background: #fff;
  position: relative;
}

.title {
  padding: 3vw 0;
  position: relative;
  opacity: 0;
}
.title01 {
  font-size: 1vw;
  /* font-weight: bold; */
  text-align: center;
  color: #314257;
}
.title02 {
  font-size: 1.9vw;
  font-weight: bold;
  /* letter-spacing: .2vw; */
  color: #124b99;
  text-align: center;
}
.underline {
  border-bottom: 3px solid #124b99;
  width: 3.6vw;
  margin: 6px auto 0 auto;
}
.banner02 > p {
  font-size: 1.5vw;
  color: #385e8f;
  /* font-weight: bold; */
  text-align: center;
  position: absolute;
  top: 2vw;
  width: 100%;
}
.icon {
  width: 100%;
  height: 18vw;
  /* background: red; */
  position: absolute;
  left: 0;
  top: 6vw;
}
.icon > div {
  width: 25%;
  float: left;
  height: 18vw;
  text-align: center;
}
.icon > div > img {
  width: 6vw;
  vertical-align: top;
}

.lunbo {
  width: 43%;
  height: 25vw;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  opacity: 0;
}
.xinwen {
  width: 55%;
  height: 25vw;
  /* background: green; */
  display: inline-block;
  margin-left: 2%;
  position: relative;
  opacity: 0;
}
.lunboDiv {
  height: 3vw;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
}
.lunboDiv01 {
  height: 4.5vw;
  width: 5vw;
  background: #ed6d00;
  position: absolute;
  bottom: 0;
  color: #fff;
  text-align: center;
}
.lunboDiv02 {
  height: 3vw;
  float: left;
  line-height: 3vw;
  font-size: 1vw;
  width: 64%;
  color: #fff;
  font-weight: normal;
  margin-left: 5.5vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lunboDiv03 {
  width: 15%;
  float: right;
  text-align: right;
  margin-right: 5px;
}
.lunboDiv03 > div {
  width: 7px;
  height: 7px;
  border-radius: 0.5vw;
  background: rgba(255, 255, 255, 0.5);
  /* float: left; */
  display: inline-block;
  margin-right: 5px;
  margin-top: 1.4vw;
  white-space: nowrap;
}
.xinwenDiv01 {
  width: 100%;
  height: 3vw;
  background: #dbe5f2;
  color: #385e8f;
  font-size: 1vw;
  font-weight: bold;
  line-height: 3vw;
  text-align: center;
}
.xinwenDiv01 > div {
  width: 25%;
  float: left;
  position: relative;
  cursor: pointer;
}
.xinwenDiv01 > div > div {
  /* width: 1px; */
  height: 50%;
  position: absolute;
  background: #385e8f;
  top: 28%;
  left: 0;
  cursor: pointer;
}
.xinwenDiv02 {
  width: 100%;
  height: 22vw;
  padding-top: 1vw;
  background: #fff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
}
.seamless-warp div {
  /* height: 2.75vw; */
  padding: 0 2vw;
  color: #a7a7a7;
  font-size: 0.8vw;
  cursor: pointer;
  /* line-height: 2.75vw; */
  margin-top: 1.3vw;
}
.seamless-warp div:hover {
  color: #385e8f;
  font-weight: bold;
}
.seamless-warp div > span {
  float: right;
}
.seamless-warp-recommend div {
  /* height: 2.75vw; */
  /* padding: 0 2vw; */
  color: #a7a7a7;
  font-size: 0.8vw;
  cursor: pointer;
  /* line-height: 2.75vw; */
  margin-top: 1.3vw;
  box-sizing: border-box;
}
.xinwenDiv02 p {
  text-align: center;
  color: #a7a7a7;
  font-size: 0.8vw;
  margin-top: 1vw;
}
.xinwenDiv02 p > span {
  height: 1px;
  width: 3vw;
  background: #e4e4e4;
  display: inline-block;
  vertical-align: middle;
}
.xinwenDiv02 p:hover {
  /* background: ; */
  color: #385e8f;
}
.canpinHover {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.canpinHover:hover {
  opacity: 1;
}
.canpinLeft {
  width: 48%;
  /* height: 36vw; */
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  /* background: red; */
}
.canpinRight {
  width: 51%;
  /* height: 36vw; */
  /* background: green; */
  display: inline-block;
  margin-left: 1%;
  cursor: pointer;
  position: relative;
}
.canpinLeft01 {
  background: url("../assets/image/chanpin/1.png") no-repeat center/100%;
  position: relative;
  opacity: 0;
  transition: all 0.3s;
}
.canpinLeft01:hover {
  background: url("../assets/image/chanpin/1.png") no-repeat center/110%;
}
.canpinLeft02 {
  margin-top: 1vw;
  position: relative;
  opacity: 0;
  background: url("../assets/image/chanpin/2.png") no-repeat center/100%;
  transition: all 0.3s;
}
.canpinLeft02:hover {
  background: url("../assets/image/chanpin/2.png") no-repeat center/110%;
}
.canpinRight01 {
  height: 26.6vw;
  width: 100%;
  overflow: hidden;
  margin-bottom: 2%;
  position: relative;
  opacity: 0;
  background: url("../assets/image/chanpin/5.gif") no-repeat center/100%;
  transition: all 0.3s;
}
.canpinRight01:hover {
  background: url("../assets/image/chanpin/5.gif") no-repeat center/110%;
}
.canpinRight02 {
  background: url("../assets/image/chanpin/3.png") no-repeat center/100%;
  transition: all 0.3s;
}
.canpinRight03 {
  background: url("../assets/image/chanpin/4.png") no-repeat center/100%;
  transition: all 0.3s;
}
.canpinRight02:hover {
  background: url("../assets/image/chanpin/3.png") no-repeat center/110% !important;
}
.canpinRight03:hover {
  background: url("../assets/image/chanpin/4.png") no-repeat center/110% !important;
}
.goods {
  width: 20.45vw;
  /* width: 18.45%; */
  height: 23vw;
  background: #fff;
  vertical-align: top;
  display: inline-block;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  padding:0
  /* opacity: 0; */
}
.goods:first-child > div {
  background: url(../assets/image/newGoods/0.png) no-repeat center;
  background-size: 122%;
}
.goods:nth-child(2) > div {
  background: url(../assets/image/newGoods/1.png) no-repeat center;
  background-size: 122%;
}
.goods:nth-child(3) > div {
  background: url(../assets/image/newGoods/2.png) no-repeat center;
  background-size: 122%;
}
.goods:nth-child(4) > div {
  background: url(../assets/image/newGoods/3.png) no-repeat center;
  background-size: 122%;
}
.goods:last-child > div {
  background: url(../assets/image/newGoods/4.png) no-repeat center;
  background-size: 122%;
}
.goods {
  margin-left: 2vw;
}
.goods > div {
  width: 100%;
  height: 16vw;
  background-size: 120%;

  transition: all 0.5s;
  cursor: pointer;
}
.goods:hover > div {
  background-size: 130%;
}
.goods:hover {
  border: 1px solid #124b99;
}
.goods:hover p {
  color: #124b99 !important;
}
.anli {
  width: 65%;
  height: 27vw;
  position: relative;
  opacity: 0;
}
.anliRight {
  width: 38%;
  height: 21vw;
  background: #fff;
  position: absolute;
  right: 0;
  top: 2.5vw;
}
.hezuoList {
  white-space: nowrap;
}
.hezuo {
  width: 15.2vw;
  height: 8vw;
  background: pink;
  display: inline-block;
  margin-left: 1vw;
  margin-top: 0.5vw;
  background: url(../assets/image/huawei.png) no-repeat center/cover;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s;
}
.hezuo:hover {
  background-size: 110% !important;
}
/* .hezuo:nth-child(5n+1){
        margin-left: 0;
    } */

.dong01 {
  animation: animate01 1s;
  animation-fill-mode: forwards;
}
.dong02 {
  animation: animate02 1s;
  animation-fill-mode: forwards;
}
.dong03 {
  animation: animate02 1.5s;
  animation-fill-mode: forwards;
}
.dong04 {
  animation: animate02 2s;
  animation-fill-mode: forwards;
}

.dong05 {
  animation: animate03 3s;
  animation-fill-mode: forwards;
}
.dong06 {
  animation: animate04 2s;
  animation-fill-mode: forwards;
}
.dong07 {
  animation: animate05 2s;
  animation-fill-mode: forwards;
}
@keyframes animate01 {
  from {
    top: 10vw;
    opacity: 0;
  }
  to {
    top: 0vw;
    opacity: 1;
  }
}
@keyframes animate02 {
  from {
    top: 30vw;
    opacity: 0;
  }
  to {
    top: 0vw;
    opacity: 1;
  }
}
@keyframes animate03 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animate04 {
  from {
    left: -50vw;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes animate05 {
  from {
    right: -50vw;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
.seamless-warp {
  height: 17vw;
  overflow: hidden;
}
.searchInput {
  height: 2vw;
  width: 10vw;
  border: 1px solid #aaa;
  /* float: right; */
  border-radius: 0.3vw;
  position: absolute;
  display: none;
  top: 1.1vw;
  left: 0;
  padding: 0 1vw;
  transform: translateX(-100%);
}
.lunboTab {
  position: absolute;
  bottom: 2vw;
  left: 50%;
  transform: translateX(-50%);
}
.lunboTab > div {
  display: inline-block;
  width: 3vw;
  border-radius: 0.5vw;
  height: 0.5vw;
  background: rgba(255, 255, 255, 0.6);
  margin: 0 0.3vw;
}
.lunboTabA {
  background: #fff !important;
}
.xinwenA {
  color: #fff !important;
  background: #385e8f !important;
}
.lunboTab02 {
  text-align: center;
  margin: 3vw 0 1vw 0;
}
.lunboTab02 > div {
  display: inline-block;
  width: 3vw;
  border-radius: 0.5vw;
  height: 0.5vw;
  background: #bdc8d7;
  margin: 0 0.3vw;
}
.lunboTabA02 {
  background: #385e8f !important;
}
.lunboTabA02-wrapper {
  cursor: pointer;
}
.left {
  width: 7vw;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
}
.right {
  width: 7vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.left:hover {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
.right:hover {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
.left:hover img {
  width: 100%;
}
.right:hover img {
  width: 100%;
}
.left > img {
  position: absolute;
  top: 50%;
  width: 80%;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.right > img {
  width: 80%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease;
}
.ellipsis_p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.anliImg > img {
  width: 46vw;
  height: 29vw;
}
/*插件外层容器 用来定义宽高等其他样式*/
/* .swiper-container {
  width: 600px;
  height: 300px;
} */
/*插件的wrapper 这是关键， 覆盖插件默认切换效果为匀速*/
/* .swiper-wrapper {
  transition-timing-function: linear;
} */
</style>
