<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="bannerImage"
        :title="'投 资 者 关 系'"
        :links="links"
      ></moduletitle>
      
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            margin-top: 3vw;
          "
        >
          华脉科技证券信息公示
        </p>
        <p class="underline"></p>
        <div class="list">
          <div
            v-for="(item, index) in list"
            :key="index"
            :style="{
              background: item.type == type ? '#124b99' : 'none',
              color: item.type == type ? '#fff' : '#124b99',
              transition: 'all 0.3s',
            }"
            @click="chooseType(item.type)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="newsList">
          <div class="news" v-for="item in tipList" :key="item.id">
            <div class="newsTxt">
              <div class="date">
                <p>{{ item.date }}</p>
                {{ item.year }}.{{ item.month }}
              </div>
              <div class="newsTitle">
                <p>{{ item.contentname }}</p>
              </div>

              <div class="checkBtn" @click="toDetail(item.url)">查看详情</div>
            </div>
          </div>
          <!-- 翻页器 -->
          <div class="pageList">
            <el-pagination
              layout="prev, pager, next"
              :total="count"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bannerMixin from "../../mixin/bannerMixin";
export default {
  components: { tapbar, bottombar, moduletitle },
  mixins: [bannerMixin],
  data() {
    return {
      isTop: false,
      list: [
        {
          name: "临时公告",
          type: 3,
        },
        {
          name: "定期报道",
          type: 2,
        },
      ],
      //类型:定期2  临时3
      type: 3,
      page: 1,
      rows: 10,
      tipList: [],
      count: 0,
      sort: "3",
      links: [],
      showImg:false
    };
  },
  mounted() {
    
    this.getLinks();
    this.getListInfo();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
  methods: {
    chooseType(type) {
      this.type = type;
      this.page = 1;
      this.getListInfo();
    },
    //查询列表
    async getListInfo() {
      const result = await this.$axios.get("/investor/api/getListByType.do", {
        params: {
          type: this.type,
          page: this.page,
          limit: this.rows,
        },
      });
      if (result.data.code === 0) {
        const tipList = result.data.data;
        this.tipList = tipList.map((item) => {
          const createtime = item.createtime.split("-");
          item.year = createtime[0];
          item.month = createtime[1];
          item.date = createtime[2];
          return item;
        });
        this.count = result.data.count;
      }
    },
    toDetail(url) {
      window.open(url);
    },
    //切换页数
    currentChange(currentPage) {
      this.page = currentPage;
      this.getListInfo();
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "投资者关系",
          url: "/investorsInfo",
          query: {},
        },
      ];
    },
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 3px solid #124b99;
  width: 5.6vw;
  margin: 6px auto 0 auto;
}
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 24px;
}
.home {
  background: #fff;
  min-height: 100vh;
}
.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.list {
  width: 100%;
  margin-top: 3vw;
}
.list > div {
  display: inline-block;
  width: 23%;
  border: 1px solid #ddd;
  text-align: center;
  color: #333;
  font-size: 15px;
  line-height: 40px;
  margin-right: 2.66%;
  box-sizing: border-box;
}
.list > div:last-child {
  margin-right: 0;
}

.newsList {
  margin-top: 3vw;
}
.news {
  padding-bottom: 2vw;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  margin-top: 2vw;
}
.newsTxt {
  width: 100%;
  /* height: 14vw; */
  position: relative;
}
.date {
  width: 80px;
  height: 80px;
  border: 1px solid #124b99;
  color: #124b99;
  text-align: center;
  font-size: 16px;
}
.date > p {
  font-size: 30px;
  font-weight: bold;
  margin-top: 2px;
}
.newsTitle {
  font-size: 20px;
  width: 90%;
  position: absolute;
  top: 8px;
  right: 0;
}
.newsContent {
  font-size: 16px;
  width: 90%;
  margin-top: 10px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.checkBtn {
  background: #124b99;
  padding: 6px 50px;
  position: absolute;
  color: #fff;
  font-size: 16px;
  bottom: 0;
  right: 0;
  text-align: center;
}
.pageList {
  text-align: right;
  margin-top: 4vw;
  margin-bottom: 4vw;
}
.pageList > span {
  display: inline-block;
  width: 1.7vw;
  height: 1.7vw;
  line-height: 1.7vw;
  text-align: center;
  font-size: 0.8vw;
  border-radius: 1.7vw;
  background: #e9e9ec;
  margin: 0 0.2vw;
}
.pageList > span:first-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
.pageList > span:last-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
</style>