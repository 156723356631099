<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="bannerImage"
        :title="'招 贤 纳 士'"
        :links="links"
      ></moduletitle>
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            margin-top: 3vw;
          "
        >
          热招岗位-社会招聘
        </p>
        <p class="underline"></p>
        <div class="search">
          <div class="searchLeft">
            <span
              style="
                padding-left: 4vw;
                border-right: 1px solid #acacac;
                padding-right: 3vw;
                cursor: pointer;
              "
              @click="isjob = true"
              >{{ jobType || "职位类型" }}
              <span style="font-size: 0.7vw; vertical-align: middle"
                >▼</span
              ></span
            >
            <div class="job" v-if="isjob" @mouseleave="isjob = false">
              <span
                v-for="(item, index) in jobs"
                :key="index"
                @click="
                  jobType = item.jobType;
                  page = 1;
                  getList();
                "
                >{{ item.jobType }}</span
              >
            </div>
            <input type="text" placeholder="搜索岗位" v-model="job" />
          </div>
          <div
            class="searchBtn"
            @click="
              page = 1;
              getList();
            "
          >
            <img src="../../assets/image/searchZW.png" alt="" />
          </div>
        </div>

        <div class="positionList">
          <div
            v-for="(item, index) in jobList"
            :key="index"
            @click="toLink('/jobDetail', item.id)"
          >
            <div style="width: 20%; color: #124b99">
              {{ item.job }}
            </div>
            <div style="width: 16%">
              {{ item.years }}
              <span style="width: 1px; height: 1vw; background: #999"></span>
              {{ item.degree }}
            </div>
            <div style="width: 15%">
              {{ item.city }}
            </div>
            <div style="width: 12%">{{ item.jobType }}</div>
            <div style="width: 16%">
              {{ item.department }}
            </div>
            <div style="width: 16%">
              {{ setNewTime(item.createTime) }}
            </div>
            <div>▶</div>
          </div>
        </div>

        <div class="pageList">
          <span @click="back()">＜</span>
          <span
            v-for="index in Math.ceil(count / 10)"
            :key="index"
            @click="
              page = index;
              getList();
            "
            :style="{
              background: page == index ? '#0E538A' : '#E9E9EC',
              color: page == index ? '#fff' : '#000',
            }"
            >{{ index }}</span
          >
          <span @click="forward()">＞</span>
        </div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bannerMixin from "../../mixin/bannerMixin";
export default {
  components: { tapbar, bottombar, moduletitle },
  mixins: [bannerMixin],
  data() {
    return {
      isTop: false,
      page: 1,
      job: "",
      jobType: null,
      jobList: "",
      count: 0,
      isjob: false,
      sort: "5",
      links: [],
    };
  },
  mounted() {
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    this.getJobs();
    this.getList();
  },
  methods: {
    toLink(url, id) {
      let routeData = this.$router.resolve({
        path: url,
        query: { id: id }
      })
      window.open(routeData.href, '_blank')
    },
    getList() {
      const params = {
        limit: 10,
        page: this.page,
      };
      this.job ? (params.job = this.job) : "";
      this.jobType && this.jobType !== "全部"
        ? (params.jobType = this.jobType)
        : "";
      this.$axios
        .get("/recruit/api/recruitList.do", {
          params,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0 && res.data.count != 0) {
            this.count = res.data.count;
            this.jobList = res.data.data;
          } else if (res.data.count == 0) {
            alert("暂未查询到该职位信息");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getJobs() {
      this.$axios
        .get("/recruit/api/getJob.do")
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            res.data.data.splice(0, 0, {
              jobType: "全部",
            });
            this.jobs = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setNewTime(time) {
      time = time.split(" ");
      return time[0];
    },
    back() {
      if (this.page == 1) {
        return;
      } else {
        this.page = this.page - 1;
      }
      this.getList();
    },
    forward() {
      if (this.page == Math.ceil(this.count / 10)) {
        return;
      } else {
        this.page = this.page + 1;
      }
      this.getList();
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "招贤纳士",
          url: "/recruitment",
          query: {},
        },
      ];
    },
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 3px solid #124b99;
  width: 5.6vw;
  margin: 6px auto 0 auto;
}
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 24px;
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.search {
  width: 100%;
  height: 4vw;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  margin-top: 3vw;
}
.searchBtn {
  float: right;
  width: 13%;
  background: #124b99;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.searchBtn > img {
  width: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.searchLeft {
  float: left;
  width: 87%;
  font-size: 1.2vw;
  color: #848484;
  line-height: 4vw;
}
.searchLeft > input {
  font-size: 1.2vw;
  border: none;
  padding-left: 1vw;
  /* border-left: 1px solid #D4D4D4;
        border-right: 1px solid #D4D4D4; */
  margin-left: 2vw;
  /* background: red; */
  width: 34vw;
  color: #000;
}
.positionList {
  margin-top: 1.5vw;
}
.positionList > div {
  width: 100%;
  height: 4vw;
  line-height: 4vw;
  text-align: left;
  background: #f6f6f6;
  margin-bottom: 1.5vw;
}
.positionList > div > div {
  display: inline-block;
  font-size: 0.8vw;
  height: 4vw;
  vertical-align: top;
  color: #030303;
  text-align: center;
}

.pageList {
  text-align: center;
  margin-top: 4vw;
  margin-bottom: 4vw;
}
.pageList > span {
  display: inline-block;
  width: 1.7vw;
  height: 1.7vw;
  line-height: 1.7vw;
  text-align: center;
  font-size: 0.8vw;
  border-radius: 1.7vw;
  background: #e9e9ec;
  margin: 0 0.2vw;
}
.pageList > span:first-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
.pageList > span:last-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
.job {
  background: #fff;
  position: absolute;
  width: 13vw;
  border: 1px solid #dddddd;
  font-size: 1vw;
  text-align: center;
  line-height: 2vw;
  left: 0;
  cursor: pointer;
}
.job > span {
  display: block;
  width: 100%;
  /* margin: 0 auto; */
  border-bottom: 1px solid #ddd;
}
.job > span:hover {
  background: #0e538a;
  color: #fff;
}
.job > span:last-child {
  border: none;
}
</style>