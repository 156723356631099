<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/workIntoHM/1.png')"
        :title="'走 进 华 脉'"
        :links="links"
      ></moduletitle>
      <bar :indexA="4"></bar>
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            margin-top: 3vw;
          "
        >
          公司视频
        </p>
        <p class="underline"></p>
        <div class="videoList">
          <div
            class="videoThree"
            v-for="item in list"
            :key="item.id"
            style="height: 17vw"
          >
            <div class="videoImg">
              <video
                ref="video"
                controls="controls"
                :src="item.url"
                :poster="item.poster"
                style="height: 100%; width: 100%"
              ></video>
            </div>
            <div class="videoTxt">
              <p style="font-size: 1vw; font-weight: bold">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/bar.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      list: [
        // {
        //   id: "2",
        //   title: "华脉科技简介",
        //   url: "http://www.huamai.cn/html/3.mp4",
        //   poster: require("../../assets/image/poster/2.png"),
        // },
        // {
        //   id: "1",
        //   title: "光缆产业简介",
        //   url: "http://www.huamai.cn/html/1.mp4",
        //   poster: require("../../assets/image/poster/1.png"),
        // },
      ],
      links: [],
    };
  },
  mounted() {
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
  methods: {
    play(index) {
      this.$refs["video"][index].play();
    },
    setIndex(index) {
      if (index == 1) {
        return 1;
      } else if (index == 2) {
        return 1;
      } else {
        return 3;
      }
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "走进华脉",
          url: "/companyProfile",
          query: {},
        },
        {
          name: "公司视频",
          url: "/companyVideo",
          query: {},
        },
      ];
    },
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 3px solid #124b99;
  width: 5.6vw;
  margin: 6px auto 0 auto;
}
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 30px;
}
.home {
  background: #fff;
  min-height: 100vh;
}
.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.videoList {
  margin-top: 2vw;
  display: flex;
  justify-content: center;
}
.videoThree {
  display: inline-block;
  height: 20vw;
  width: 34vw;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}
.videoThree:nth-child(3n-1) {
  margin: 0 2%;
}
.videoImg {
  width: 100%;
  height: 13vw;
  background: #000;
  position: relative;
}
.play {
  width: 5vw;
  position: absolute;
  bottom: 1vw;
  left: 1vw;
  z-index: 99;
}
.videoTxt {
  text-align: center;
  font-size: 30px;
  line-height: 1.3vw;
  margin-top: 1.3vw;
}
</style>