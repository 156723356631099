<template>
  <div class="home">
    <tapbar :isTop="isTop" @to-search="toSearch"></tapbar>
    <moduletitle
      :img="bannerImage"
      :title="'全站搜索'"
      :links="links"
    ></moduletitle>
    <div class="message">
      <div class="message-title">搜 索 结 果</div>
      <div class="message-line"></div>
      <div class="search-wrapper">
        <input type="text" v-model="searchValue" @keyup.enter="getSearchInfo"/>
        <img
          src="../../assets/image/search-btn.png"
          alt=""
          @click="getSearchInfo"
        />
      </div>
      <div class="message-content">
        <div class="message-content-left">
          <div class="left-title">类别</div>
          <div class="left-content">
            <div
              class="left-item-wrapper"
              :style="{
                backgroundColor: chooseTypeName === item.type ? '#124B99' : '',
                color: chooseTypeName === item.type ? '#FFF' : '',
              }"
              v-for="item in types"
              :key="'type_' + item.type"
              @click="chooseTypeHandler(item.type)"
            >
              <div class="left-item">{{ item.type }}({{ item.count }})</div>
            </div>
          </div>
        </div>
        <div class="message-content-right">
          <div class="right-title">{{ count }}条结果</div>
          <div
            class="right-item"
            v-for="(item,index) in list"
            :key="index"
            @click="toPageByType(item.type, item.id)"
          >
            <div class="right-item-title">{{ item.title }}</div>
            <div class="right-item-content">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="pageList">
        <el-pagination
          layout="prev, pager, next"
          :total="count"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <bottombar style="position: relative"></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bannerMixin from "../../mixin/bannerMixin";
export default {
  components: { tapbar, bottombar, moduletitle },
  mixins: [bannerMixin],
  data() {
    return {
      isTop: false,
      sort: "7",
      links: [],
      types: [],
      page: 1,
      count: 0,
      list: [],
      //传给后台的参数
      chooseType: "",
      //用于显示样式
      chooseTypeName: "",
      searchValue: "",
    };
  },
  mounted() {
    this.searchValue = this.$route.params.searchValue;
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    this.getArticleType(true);
    this.getArticle();
  },
  methods: {
    // 全局搜索
    toSearch(val){
      this.searchValue=val;
      this.getArticle();
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "全站搜索",
          url: "/search",
          query: {},
        },
      ];
    },
    //查询左边菜单
    async getArticleType(isFirstType) {
      const params = {};
      if (this.searchValue) {
        params.str = this.searchValue;
      }
      const result = await this.$axios.get("/Selecter/api/getCount.do", {
        params,
      });
      if (result.data.code === 0) {
        this.types = result.data.data;
        isFirstType ? (this.chooseTypeName = result.data.data[0].type) : false;
      }
    },
    chooseTypeHandler(type) {
      this.chooseTypeName = type;
      type === "全部" ? (type = "") : false;
      this.chooseType = type;
      this.page = 1;
      this.getArticle();
    },
    //查询文章
    async getArticle() {
      const params = {
        page: this.page,
        limit: 10,
      };
      if (this.chooseType) {
        params.type = this.chooseType;
      }
      if (this.searchValue) {
        params.str = this.searchValue;
      }
      const result = await this.$axios.get("/Selecter/api/getSelect.do", {
        params,
      });
      if (result.data.code === 0) {
        this.getArticleType();
        this.count = result.data.count;
        this.list = result.data.data;
      }
    },
    //切换页数
    currentChange(currentPage) {
      this.page = currentPage;
      this.getArticle();
    },
    //搜索
    getSearchInfo() {
      this.page = 1;
      this.getArticle();
    },
    //根据不同的type跳转页面
    toPageByType(type, id) {
      if (type === "产品") {
        this.$router.push({ path: "/product", query: { id } });
      } else if (type === "解决方案") {
        this.$router.push({ path: "/productDetail", query: { id } });
      } else if (
        type === "公司新闻" ||
        type === "行业动态" ||
        type === "展会信息" ||
        type === "员工活动"
      ) {
        this.$router.push({ path: "/newsInfoDetails", query: { id } });
      } else if (type === "资质荣誉") {
        this.$router.push({ path: "/honor" });
      } else if (type === "招贤纳士") {
        this.$router.push({ path: "/jobDetail", query: { id } });
      }
    },
  },
};
</script>
<style scoped>
.home {
  background: #fff;
  min-height: 100vh;
}
.message {
  width: 65vw;
  /* min-width: 996px; */
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.message-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #124b99;
  margin-top: 2vw;
}
.message-line {
  width: 10vw;
  height: 2px;
  margin: 0.6vw auto 2vw auto;
  background-color: #124b99;
}
.message-content {
  display: flex;
}
.message-content-left {
  flex: 0 0 14vw;
  margin-right: 4vw;
}
.left-title {
  background-color: #124b99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14vw;
  height: 4vw;
  color: #fff;
  font-size: 1.2vw;
}
.left-content {
  border: 1px solid #999;
}
.left-item-wrapper {
  padding: 0 0.6vw;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.left-item {
  border-bottom: 1px solid #999;
  padding: 1vw;
}
.message-content-right {
  flex: 0 0 47vw;
}
.right-title {
  font-size: 1.8vw;
}
.right-item {
  border-bottom: 1px solid #999;
  padding-bottom: 1vw;
  margin-bottom: 1vw;
  cursor: pointer;
}
.right-item-title {
  font-size: 1.2vw;
}
.right-item-content {
  font-size: 0.8vw;
  color: #999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 47vw;
}
.pageList {
  text-align: right;
  margin-top: 4vw;
  margin-bottom: 4vw;
}
.pageList > span {
  display: inline-block;
  width: 1.7vw;
  height: 1.7vw;
  line-height: 1.7vw;
  text-align: center;
  font-size: 0.8vw;
  border-radius: 1.7vw;
  background: #e9e9ec;
  margin: 0 0.2vw;
}
.pageList > span:first-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
.pageList > span:last-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
.search-wrapper {
  height: 63px;
  margin-bottom: 4vw;
  display: flex;
  cursor: pointer;
}
.search-wrapper > input {
  flex: 1;
  padding: 0 1vw;
  font-size: 1.6vw;
}
.search-wrapper > img {
  width: 106px;
  height: 63px;
}
</style>