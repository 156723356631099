<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper" style="min-height: 100vh">
        <!-- :img="require('/src/assets/image/banner/1.png')" -->
      <moduletitle
        :img="$imgLink+content.imgurl"
        :title="''"
        :links="links"
      ></moduletitle>
      <bar :indexA="2"></bar>
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            padding: 3vw 0 0.5vw 0;
          "
        >
          {{ content.title }}
        </p>
        <!-- <p style="text-align: center; font-size: 18px; color: #000">
          时间：{{ content.createtime }}
        </p> -->

        <div v-html="content.content"></div>

        <!-- <div
          style="height: 1px; background: #e5e5e5; width: 95%; margin: 2vw auto"
        ></div> -->
        <!-- <div class="btnList" style="height: 100px" v-if="$route.query.pid">
          <div
            style="text-align: right; cursor: pointer"
            v-if="prev"
            @click="toUrl(prev.id)"
          >
            <div>
              <span>上一条</span>
              <p>{{ prev.title }}</p>
            </div>
          </div>
          <div
            style="float: right; cursor: pointer"
            v-if="next"
            @click="toUrl(next.id)"
          >
            <div>
              <span>下一条</span>
              <p>{{ next.title }}</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/category.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      content: {
        title: "",
      },
      links: [],
      prev: null,
      next: null,
    };
  },
  mounted() {
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    this.getList();
    this.getPrev();
    this.getNext();
  },

  methods: {
    getList() {
      this.$axios
        .get("/Case/api/selectById.do", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((res) => {
          console.log(res);
          this.content = res.data.data;
          this.getParent();
          // console.log(this.content,"查看图片999999999999")
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 上一页，下一页
    getPrev() {
      this.$axios
        .get("/Case/api/selectNextOrLastByParam.do", {
          params: {
            type: 0,
            solution_id: this.$route.query.pid,
            case_id: this.$route.query.id,
          },
        })
        .then((res) => {
          console.log(res);
          this.prev = res.data.data;
          //   this.content = res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNext() {
      this.$axios
        .get("/Case/api/selectNextOrLastByParam.do", {
          params: {
            type: 1,
            solution_id: this.$route.query.pid,
            case_id: this.$route.query.id,
          },
        })
        .then((res) => {
          console.log(res);
          this.next = res.data.data;
          //   this.content = res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查询成功案例的父级
    async getParent() {
      const result = await this.$axios.get("/Programme/api/selectFirst.do");
      const list = result.data.data;
      const links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "产品及解决方案",
          url: "/productCenter",
          query: { pid: list[0].id },
        },
      ];
      if (this.$route.query.pid) {
        const parent = list.find((item) => {
          return item.id == this.$route.query.pid;
        });
        if (parent) {
          links.push({
            name: parent.solutionName,
            url: "/productCenter",
            query: { pid: parent.id },
          });
        }
      } else {
        const parent = list.find((item) => {
          return item.id == this.content.solutionId;
        });
        if (parent) {
          links.push({
            name: parent.solutionName,
            url: "/productCenter",
            query: { pid: parent.id },
          });
        }
      }
      this.links = links;
    },
    toUrl(id) {
      this.$router.push({
        query: {
          id,
          pid: this.$route.query.pid,
        },
      });
      this.getParent();
      this.getList();
      this.getPrev();
      this.getNext();
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  /* padding-bottom: 24px; */
  padding-bottom: 40px;
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  width: 65vw;
  min-width: 960px;
  margin: 0 auto;
  position: relative;
}
.text {
  line-height: 30px;
  width: 100%;
  text-align: justify;
  margin-top: 2vw;
  font-size: 15px;
}
.img {
  width: 100%;
  /* height: 20vw; */
  background: #0e538a;
  margin-top: 3vw;
}
.btnList > div {
  display: inline-block;
  width: 45%;
  font-size: 15px;
  text-align: center;
}
/* .btnList > div > div {
  display: inline-block;
  width: 9vw;
  height: 2vw;
  line-height: 2vw;
  font-size: 1vw;
  border: 1px solid #dbdbdb;
  text-align: center;
  margin-bottom: 1vw;
} */
.btnList > div > div > span {
  display: inline-block;
  width: 9vw;
  height: 2vw;
  line-height: 2vw;
  font-size: 1vw;
  border: 1px solid #dbdbdb;
  text-align: center;
  margin-bottom: 1vw;
}
i {
  font-style: normal;
}
</style>