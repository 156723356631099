<template>
  <div class="bar">
    <div class="barTitle">
      <div
        v-for="(item, index) in productList"
        :key="index"
        :style="{
          width: countWidth(index),
          color: indexA == index ? '#083875' : '#000000',
          textAlign: countAlign(index),
        }"
        @click="queryList(item.id, index)"
      >
        {{ item.solutionName }}
        <span v-if="productList.length != index + 1"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bar",
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
    indexA: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      details: "",
    };
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    countWidth(index) {
      let width = 100 / (this.productList.length - 1);
      if (index == 0 || index == this.productList.length - 1) {
        return width / 2 + "%";
      } else {
        return width + "%";
      }
    },
    countAlign(index) {
      if (index == 0) {
        return "left";
      } else if (index == this.productList.length - 1) {
        return "right";
      } else {
        return "center";
      }
    },
    queryList(id) {
      this.$router.push({ path: "/productCenter?pid=" + id });
      //     this.$axios.get("/Programme/api/selectByPid.do",{params:{id:this.$route.query.pid}}).then((res) => {
      //       console.log(res.data)
      //       this.$emit("getDetails",res.data.data)
      //     })
      //     .catch((err) => {
      //      console.log(err)
      //     });
    },
  },
};
</script>
<style scoped>
.bar {
  background: #f6f6f6;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e4e4e4;
}
.barTitle {
  width: 65vw;
  min-width: 960px;
  height: 4vw;
  margin: 0 auto;
  position: relative;
}
.barTitle > div {
  float: left;
  /* width: 16.6%; */
  line-height: 60px;
  text-align: center;
  font-size: 15.5px;
  position: relative;
  color: #333;
  font-weight: 400;
  cursor: pointer;
}
.barTitle > div > span {
  display: inline-block;
  height: 1.5vw;
  width: 1px;
  background: #e2e2e2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
</style>