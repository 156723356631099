var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('tapbar',{attrs:{"isTop":_vm.isTop}}),_c('div',{staticClass:"content-wrapper"},[_c('moduletitle',{attrs:{"img":require('/src/assets/image/workIntoHM/1.png'),"title":'走 进 华 脉',"links":_vm.links}}),_c('bar',{attrs:{"indexA":3}}),_c('div',{staticClass:"message"},[_c('div',{staticClass:"list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,style:({
            background: index == _vm.listIndexA ? '#124b99' : 'none',
            color: index == _vm.listIndexA ? '#fff' : '#124b99',
          }),on:{"click":function($event){_vm.listIndexA = index;
            _vm.page = 1;
            _vm.getList();}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"newsList"},_vm._l((_vm.newList),function(item,index){return _c('div',{key:index,staticClass:"news",on:{"click":function($event){return _vm.toNewsInfoDetails(item.id,index)}}},[_c('div',{staticClass:"newsImg",style:({
              background: 'url(' + item.imgurl + ') no-repeat center/cover',
            })}),_c('div',{staticClass:"newsTxt"},[_c('div',{staticClass:"date"},[_c('p',[_vm._v(_vm._s(_vm.breakUpDate(item.createtime)[2]))]),_vm._v(" "+_vm._s(_vm.breakUpDate(item.createtime)[0])+"."+_vm._s(_vm.breakUpDate(item.createtime)[1])+" ")]),_c('div',{staticStyle:{"position":"absolute","width":"80%","top":"0","right":"0"}},[_c('div',{staticClass:"newsTitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"newsContent"},[_vm._v(_vm._s(item.present))])]),_c('div',{staticClass:"checkBtn"},[_vm._v("查看详情")])])])}),0),_c('div',{staticClass:"pageList"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","total":_vm.count},on:{"current-change":_vm.currentChange}})],1)])],1),_c('bottombar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }