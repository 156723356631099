<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/workIntoHM/1.png')"
        :title="'走 进 华 脉'"
        :links="links"
      ></moduletitle>
      <bar :indexA="1"></bar>
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            margin-top: 3vw;
          "
        >
          组织架构
        </p>
        <p class="underline"></p>
        <div class="img">
          <img src="../../assets/image/workIntoHM/8.png" alt="" />
        </div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/bar.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      links: [],
    };
  },
  methods: {
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "走进华脉",
          url: "/companyProfile",
          query: {},
        },
        {
          name: "组织架构",
          url: "/organizationalStructure",
          query: {},
        },
      ];
    },
  },
  mounted() {
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 3px solid #124b99;
  width: 5.6vw;
  margin: 6px auto 0 auto;
}
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 30px;
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.text {
  line-height: 2vw;
  width: 100%;
  text-align: justify;
  margin-top: 2vw;
  font-size: 1.1vw;
}
.img {
  width: 100%;
  /* height: 20vw; */
  /* background: #0E538A; */
  margin-top: 3vw;
}
</style>