<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/workIntoHM/1.png')"
        :title="'走 进 华 脉'"
        :links="links"
      ></moduletitle>
      <bar :indexA="2"></bar>
      <div style="padding-top: 3vw">
        <div class="wrapper" v-for="(item, index) in list" :key="item.id">
          <div class="wrapper-title">
            <div class="wrapper-title-left">{{ item.title }}</div>
            <div class="wrapper-title-right">
              <img
                src="../../assets/image/plus.png"
                alt=""
                @click="showList(index)"
                v-if="!item.isShow"
              />
              <img
                src="../../assets/image/cut.png"
                alt=""
                @click="hideList(index)"
                v-else
              />
            </div>
          </div>
          <transition>
            <div class="wrapper-content" v-if="item.isShow">
              <div
                class="wrapper-item"
                v-for="erjiItem in item.erji"
                :key="erjiItem.id"
                @mouseenter="onEnter(erjiItem)"
                @mouseleave="onLeave"
              >
                <div class="wrapper-item-cont" style="position: relative">
                  <img :src="erjiItem.image" alt="" />
                  <div v-if="currentHover == erjiItem.id" class="magnifier">
                    <img
                      src="./../../assets/image/searchZW.png"
                      style="
                        width: 30%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                      "
                      @click="onImage(erjiItem.image)"
                    />
                  </div>
                </div>
                <div class="wrapper-item-title">{{ erjiItem.title }}</div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div
      class="popup"
      v-if="showPopup"
      @click.self="(showPopup = false), (currentImageUrl = null)"
    >
      <img
        :src="currentImageUrl"
        style="
          width: auto;
          height: 80vh;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      />
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/bar.vue";
// import Swiper from "swiper";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      list: [],
      links: [],
      currentHover: null, //当前悬浮index
      showPopup: false, //展示当前放大效果
      currentImageUrl: null, //当前放大图片地址
    };
  },
  mounted() {
    this.getLinks();
    this.getList();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
  methods: {
    toLeft() {
      // this.mySwiper.slideNext();
    },
    toRight() {
      // this.mySwiper.slidePrev();
    },
    getList() {
      this.$axios
        .get("/zzry/api/selectAll.do", {
          params: {
            type: this.listIndexA + 1,
            limit: 10,
            page: this.page,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            var list = [];
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].pid == 0) {
                if (i === 0 || i === 1 || i === 2) {
                  res.data.data[i].isShow = true;
                } else {
                  res.data.data[i].isShow = false;
                }
                list.push(res.data.data[i]);
              }
            }

            for (let k = 0; k < list.length; k++) {
              list[k].erji = [];
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].pid != 0) {
                  if (res.data.data[i].pid == list[k].id) {
                    list[k].erji.push(res.data.data[i]);
                  }
                }
              }
            }

            this.list = list;
            // for (let i = 0; i < this.list.length; i++) {
            //   this.$nextTick(() => {
            //     new Swiper(".swiper-container" + (i + 1), {
            //       slidesPerView: 5,
            //       spaceBetween: 30,
            //       observer: true,
            //       loop: true,
            //       observeParents: true,
            //       navigation: {
            //         nextEl: ".swiper-button-next" + (i + 1),
            //         prevEl: ".swiper-button-prev" + (i + 1),
            //       },
            //     });
            //   });
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hideList(index) {
      console.log(index);
      this.list[index].isShow = false;
    },
    showList(index) {
      this.list[index].isShow = true;
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "走进华脉",
          url: "/companyProfile",
          query: {},
        },
        {
          name: "资质荣誉",
          url: "/honor",
          query: {},
        },
      ];
    },
    // 点击放大
    onImage(data) {
      this.showPopup = true;
      this.currentImageUrl = data;
    },
    // 鼠标移入
    onEnter(data) {
      // console.log(data,"鼠标移入")
      this.currentHover = data.id;
    },
    // 鼠标移出
    onLeave() {
      this.currentHover = null;
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 30px;
}
.home {
  background: #fff;
  min-height: 100vh;
}
.wrapper-parent {
  margin-bottom: 360px;
}
.wrapper {
  width: 65vw;
  margin: 16px auto 0 auto;
}
.wrapper-title {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  font-size: 1.5vw;
  background-color: #124b99;
  color: #fff;
  justify-content: space-between;
}
.wrapper-title-left {
}
.wrapper-title-right {
  width: 2vw;
}
.wrapper-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}
.wrapper-item {
  flex: 0 0 12.3vw;
  margin-right: 1%;
  height: 20vw;
}
.magnifier {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 74, 126, 0.7);
}
.wrapper-item-cont {
  width: 100%;
  height: 16vw;
  background-color: #e7e7e7;
}
.wrapper-item-cont > img {
  height: 16vw;
}
.wrapper-item:nth-child(5n) {
  margin-right: 0;
}
.wrapper-item-title {
  background-color: #acacac;
  color: #fff;
  padding: 4px 0;
  font-size: 1vw;
  text-align: center;
  width: 12.3vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease;
}
.popup {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
