<template>
  <div>
    <div class="tit">相关产品</div>
    <div class="cont">
      <div
        id="contHtml"
        :class="showMore != null && !showMore ? 'cont-html' : ''"
        v-html="productDescription"
      ></div>
      <div class="moreBtn02" v-if="showMore != null">
        <span v-if="showMore != null && !showMore" @click="showMore = !showMore"
          >点击加载更多 ▼</span
        >
        <span v-if="showMore != null && showMore" @click="showMore = !showMore"
          >点击收起 ▲</span
        >
      </div>
    </div>
    <div class="productList">
      <!-- 一级 -->
      <div v-for="(item, index) in breakUpOne" :key="index" class="breakUpOne">
        <span
          class="firstTit"
          :class="
            index01A == index && index02A == index02 ? 'breakUpOneActive' : ''
          "
          v-for="(item02, index02) in item"
          :key="index02"
          @click="
            index01A == index ? (index01A = null) : (index01A = index);
            index02A = index02;
            breakUp01(item02.ischild, item02.id);
          "
          >{{ item02.productname }}
          <span v-if="item02.ischild > 0">{{
            index01A == index && index02A == index02 ? "▲" : "▼"
          }}</span>
        </span>
        <div
          class="firstSonBlock"
          style="width: 100%; margin-top: 10px"
          v-if="index01A == index"
        >
          <!-- 二级 -->
          <div
            v-for="(item03, index03) in breakUpTwo"
            :key="index03"
            class="breakUpTwo"
          >
            <span
              style="font-size: 0.8vw"
              v-for="(item04, index04) in item03"
              :key="index04"
              @click="
                breakUp02(item04.ischild, item04.id);
                index03A == index03 ? (index03A = null) : (index03A = index03);
                index04A = index04;
              "
              >{{ item04.productname }}
              <span v-if="item04.ischild > 0">{{
                index03A == index03 && index04A == index04 ? "▲" : "▼"
              }}</span>
            </span>
            <div
              style="background: red; width: 100%"
              v-if="index03A == index03"
            >
              <div
                v-for="(item05, index05) in breakUpThree"
                :key="index05"
                class="breakUpTwo"
                style="background: #3e93d6"
              >
                <span
                  style="font-size: 0.8vw"
                  v-for="(item06, index06) in item05"
                  :key="index06"
                  @click="breakUp03(item06.id)"
                  >{{ item06.productname }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "relatedProducts",
  props: ["productDescription", "productList", "pid"],
  data() {
    return {
      list: [
        { name: "OTDR（测试设备）", list: "" },
        { name: "OSW（光开关）", list: "" },
        { name: "OPM（光功率）", list: "" },
        { name: "WDM（合波器）", list: "" },
        { name: "FILTER（滤波器）", list: "" },
        { name: "OLP（光保护）", list: "" },
        { name: "OS（光源）", list: "" },
        { name: "产品名称", list: "" },
        { name: "产品名称", list: "" },
        { name: "产品名称", list: "" },
        { name: "产品名称", list: "" },
        { name: "产品名称", list: "" },
      ],
      breakUpOne: [],
      breakUpTwo: [],
      breakUpThree: [],
      shouerji: false,
      showMore: null,
      shousanji: false,
      index01A: null,
      index02A: null,
      index03A: null,
      index04A: null,
      index: null,
    };
  },
  mounted() {
    // this.breakUp()
    this.breakUpOne = this.breakUp(this.productList);
    // this.breakUpTwo = this.breakUp01(this.list);
    // this.breakUpThree = this.breakUp01(this.list);

    this.$refs.breakUpOne4;
    // 判断相关产品模块高度
    let htmlHeight = document.getElementById("contHtml").offsetHeight;
    if (htmlHeight < 400) {
      this.showMore = null;
    } else {
      this.showMore = false;
    }
    // console.log(htmlHeight,"htmlHeight")
  },
  methods: {
    // 点击查看是否有二级，有赋值二级
    async breakUp01(ischild, id) {
      // console.log(ischild, id, "888888888");
      if (ischild > 0) {
        this.breakUpTwo = await this.getProducts02(id);
      } else {
        // window.open(
        //   `http://www.huamai.cn/html/huamaihome/#/product?id=${id}&pid=${this.pid}`
        // );
        let routeData = this.$router.resolve({
          path: "product", query: { id,pid:this.pid } 
        })
        window.open(routeData.href, '_blank')
        // this.$router.push({
        //   path: "/product",
        //   query: { id: id, pid: this.pid },
        // });
      }
    },

    // 点击查看是否有三级，有赋值三级
    async breakUp02(ischild, id) {
      if (ischild > 0) {
        this.breakUpThree = await this.getProducts02(id);
      } else {
        // window.open(
        //   `http://www.huamai.cn/html/huamaihome/#/product?id=${id}&pid=${this.pid}`
        // );

        let routeData = this.$router.resolve({
          path: "product", query: { id,pid:this.pid } 
        })
        window.open(routeData.href, '_blank')
        // this.$router.push({
        //   path: "/product",
        //   query: { id: id, pid: this.pid },
        // });
      }
    },
    async breakUp03(id) {
      // window.open(
      //   `http://www.huamai.cn/html/huamaihome/#/product?id=${id}&pid=${this.pid}`
      // );
      let routeData = this.$router.resolve({
          path: "product", query: { id,pid:this.pid } 
        })
        window.open(routeData.href, '_blank')
      //this.$router.push({ path: "/product", query: { id: id, pid: this.pid } });
    },

    // 拆分拆分产品
    breakUp(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i += 4) {
        var add = arr.slice(i, i + 4);
        result.push(add);
      }
      return result;
    },

    // 查询第二级
    async getProducts02(id) {
      const result = await this.$axios.get(
        "/Product/api/getProductListByPid.do",
        {
          params: { pid: id },
        }
      );
      if (result.data.code === 0) {
        return this.breakUp(result.data.data);
      }
    },
  },
  watch: {
    productList(val, oldVal){//普通的watch监听
        console.log(oldVal)
        this.breakUpOne = this.breakUp(val);
        this.$refs.breakUpOne4;
        // 判断相关产品模块高度
        let htmlHeight = document.getElementById("contHtml").offsetHeight;
        if (htmlHeight < 400) {
          this.showMore = null;
        } else {
          this.showMore = false;
        }
     },
  },
};
</script>
<style scoped>
.breakUpOne > span,
.breakUpTwo > span {
  width: 24.1%;
  display: inline-block;
  height: 3vw;
  line-height: 3vw;
  text-align: center;
  border: 1px solid #dddddd;
  font-size: 1vw;
  box-sizing: border-box;
  margin-right: 1.2%;
  margin-top: 1vw;
  transition: all 0.1s;
  cursor: pointer;
}
.breakUpOne > span:hover {
  /* background: #1a5c90; */
  background-image: url("./../../assets/image/hoverList/firstImgHover.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  cursor: pointer;
}
.breakUpOneActive {
  background-image: url("./../../assets/image/hoverList/firstImgHover.png") !important;
  color: #fff;
}
.breakUpOne > span:nth-child(4n),
.breakUpTwo > span:nth-child(4n) {
  margin-right: 0;
}
.breakUpTwo {
  /* background: #1a5c90; */
}
.breakUpTwo > span {
  color: #fff;
  border: none;
  margin-top: 0;
}

.product {
  max-height: 30vh;
  overflow: hidden;
  position: relative;
}
.cont-html {
  height: 400px;
  overflow: hidden;
}
.moreBtn {
  position: absolute;
  width: 100%;
  height: 10vh;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 255)
  );
  bottom: 0;
}
.moreBtn02 {
  font-size: 1.1vw;
  line-height: 8vh;
  text-align: center;
  color: #0e538a;
  cursor: pointer;
}
.productList .firstTit {
  background-image: url("./../../assets/image/hoverList/firstImg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.firstSonBlock {
  background-image: url("./../../assets/image/hoverList/breakUpTwoBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
