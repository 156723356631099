<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="bannerImage"
        :title="'走 进 华 脉'"
        :links="links"
      ></moduletitle>
      <bar :indexA="0"></bar>
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 16px;
            color: #314257;
            padding: 3vw 0 0.5vw 0;
          "
        >
          （股票代码：603042）
        </p>
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
          "
        >
          南京华脉科技股份有限公司
        </p>
        <div class="graphic" style="text-align: right">
          <div class="graphicTxt">
            <p>
              南京华脉科技股份有限公司肩负着“为客户创造价值，为中国通信发展做贡献”的历史使命，持续致力于“企业以人为本，人以责任为先”的发展理念，高标准、零缺陷、顾客的期望是公司追求的目标。公司以工匠精神打造产品，并以城墙砖式的服务让每一件产品都可以全流程追溯。奉行求实、进取、创新、协同、分享的核心价值观、培养有态度、有能力、有素质的员工，共同面对市场机遇和挑战。在创造经济价值之时，华脉始终不忘初心，践行社会责任，扶贫济困，救助灾害，20年来，积极投身社会公益事业，为社会和谐稳定与发展贡献着力量。
            </p>
          </div>
          <div class="graphicImg">
            <img src="../../assets/image/workIntoHM/2.png" alt="" />
          </div>
        </div>
        <div class="graphic" style="width:100%;">
          <div class="graphicImg">
            <img src="../../assets/image/workIntoHM/3.png" alt="" />
          </div>
          <div class="graphicTxt" style="right: 0 !important; left: auto">
            <p style="margin-left: 10%">
              南京华脉科技股份有限公司创建于1998年， 2017年6月2日，公司在上海证券交易所主板A股上市。20年间，华脉一直深耕光通信、无线通信领域，铸就产业坚实基础。<br/><br/>
              公司不断丰富和完善有线、无线通信基础设施产业，发展数据中心、物联网、系统集成和信息通讯增值业务，形成新的四大业务板块，并提升效能和成本优势，逐步由“无源”向“有源”拓展，由配套设施向通信系统及应用拓展，进一步成长为给客户提供“五横一纵”云到“端+边”的软硬件融合系统解决方案提供商。公司通过通信技术、物联网、高端制造等战略版图，实现与客户、合作伙伴共生、共享、共赢的生态价值圈，多年来华脉与中国电信、中国移动、中国联通、中国铁塔等几大运营商，与国内大型通信设备商，以及中石化、中石油、国家油气管网、电力、广电、市政、铁路、城市轨道交通等行业客户，均保持稳定的合作关系。
            </p>
          </div>
        </div>
        <div
          style="margin-top: 3vw; position: relative; font-size: 16px"
          class="textAnimateTop"
        >
          <img src="../../assets/image/companyProfile.png" alt="" />
          <div class="textAnimate" style="left: 8%">
            <span>
              <count-up
                :delay="1500"
                :endVal="20"
                :options="options"
              ></count-up>
            </span>
            <p>20余年发展</p>
          </div>

          <div class="textAnimate" style="left: 26%">
            <span
              ><count-up
                :delay="1500"
                :endVal="31"
                :options="options"
              ></count-up
            ></span>
            <p>31个办事处</p>
          </div>

          <div class="textAnimate" style="left: 47%">
            <span
              ><count-up
                :delay="1500"
                :endVal="1500"
                :options="options"
              ></count-up
            ></span>
            <p>1500多名员工</p>
          </div>

          <div class="textAnimate" style="left: 67%">
            <span
              ><count-up
                :delay="1500"
                :endVal="16058"
                :options="options"
              ></count-up
            ></span>
            <p>16058万注册资金</p>
          </div>
        </div>
        <p class="text">
          同时，公司积极拓展海外通信设备市场，产品出口韩国、泰国、印尼等多个国家和地区。如今，公司的营销和服务网络已覆盖全球，产品和综合解决方案全面服务国内31个省市和国外50多个国家和地区，公司坚持以技术创新为企业发展导向，健全的产、学、研合作机制，荣获科学进步一等奖，并取得500余项专利，掌握诸多核心技术，参与制定50多项国家、行业标准。
        </p>
        <p class="text">
          华脉科技将锲而不舍、持之以恒地走创新发展之路，以工匠精神打造产品，围绕行业发展趋势、以战略目标为引领与社会各界朋友及客户、合作伙伴共同建设“智慧中国”的美好明天!
        </p>
      </div>
    </div>
    <!-- <div style="height: 18vw"></div> -->
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/bar.vue";
import bannerMixin from "../../mixin/bannerMixin";
import countUp from "vue-countup-v2";
export default {
  components: { tapbar, bottombar, moduletitle, bar, countUp },
  mixins: [bannerMixin],
  data() {
    return {
      isTop: false,
      sort: "1",
      links: [],
      options: {
        useGrouping: false,
      },
    };
  },
  methods: {
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "走进华脉",
          url: "/companyProfile",
          query: {},
        },
        {
          name: "公司简介",
          url: "/companyProfile",
          query: {},
        },
      ];
    },
  },
  mounted() {
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 30px;
}
.home {
  background: #fff;
  min-height: 100vh;
}
.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.graphic {
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  /* position: relative; */
  display: flex;
  align-items: center;

  line-height: 14px;
  color: #333;
}
.graphic > div {
  display: inline-block;
  vertical-align: top;
}
.graphicTxt {
  width: 53%;
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0; */
}
.graphicImg {
  width: 47%;
}
.graphicTxt > p {
  line-height: 30px;
  width: 90%;
  text-align: justify;
  /* font-size: 1vw; */
}
.text {
  line-height: 30px;
  width: 100%;
  text-align: justify;
  margin-top: 2vw;
  font-size: 16px;
  color: #333;
}
.textAnimate {
  position: absolute;
  width: 23%;
  text-align: center;
  /* background: red; */
  color: #114a98;
  top: -1vw;
  /* font-family: syht02; */
}
.textAnimate > span {
  font-size: 36px;
}
</style>