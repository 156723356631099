<template>
  <div>
    <div class="tit">成功案例</div>
    <!-- <p
      style="
        text-align: center;
        font-size: 18px;
        color: #314257;
        padding: 3vw 0 0.5vw 0;
      "
    >
      SUCCESSFUL CASES
    </p>
    <p
      style="
        text-align: center;
        font-size: 36px;
        color: #083875;
        font-weight: bold;
      "
    >
      成功案例
    </p> -->
    <div class="cases" v-if="successList && successList.length > 0">
          <el-carousel style="width: 100%; border: none; padding-bottom: 30px">
            <el-carousel-item
              v-for="(item, index) in successList"
              :key="index"
              style="display: flex; justify-content: space-between"
            >
              <div
                v-for="(i, ind) in item"
                :key="ind"
                style="width: 48%; border: 1px solid #dcdcdc"
                @click="
                  toLink(i.id)
                "
              >
                <div
                  class="casesImg"
                  :style="{
                    background: 'url(' + i.imgurl + ') no-repeat center/cover',
                  }"
                ></div>
                <p
                  style="
                    font-size: 23px;
                    font-weight: bold;
                    text-align: center;
                    padding: 1vw 0;
                  "
                >
                  {{ i.title }}
                </p>
                <p
                  style="
                    text-align: left;
                    padding: 0 1vw;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    font-size: 15px;
                    color: #666;
                  "
                >
                  {{ i.detail }}
                </p>
                <div
                  style="
                    text-align: right;
                    padding-right: 1.4vw;
                    margin-top: 1.3vw;
                  "
                >
                  <span
                    style="
                      display: inline-block;
                      width: 8vw;
                      height: 2vw;
                      line-height: 2vw;
                      text-align: center;
                      border: 1px solid #dcdcdc;
                      color: #0d3c78;
                    "
                    >了解详情></span
                  >
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
    <!-- <div class="cases">
      <div
        v-for="(item, index) in successList"
        :key="index"
        @click="toLink(item.id)"
      >
        <div
          class="casesImg"
          :style="{
            background: 'url(' + item.imgurl + ') no-repeat center/cover',
          }"
        ></div>
        <p
          style="
            font-size: 1.2vw;
            font-weight: bold;
            text-align: center;
            padding: 1vw 0;
          "
        >
          {{ item.title }}
        </p>
        <p
          style="
            text-align: left;
            padding: 0 1vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          "
        >
          {{ item.detail }}
        </p>
        <div style="text-align: right; padding-right: 1.4vw; margin-top: 1.3vw">
          <span
            style="
              display: inline-block;
              width: 8vw;
              height: 2vw;
              line-height: 2vw;
              text-align: center;
              border: 1px solid #dcdcdc;
              color: #0d3c78;
            "
            >了解详情></span
          >
        </div>
      </div>
    </div> -->


  </div>
</template>
<script>
export default {
  name: "successfulCases",
  props: ["successList", "pid"],
  // data() {
  //   return {
  //     cases: [
  //       {
  //         name: "扬州中航宝胜海缆监测",
  //         content:
  //           "扬州中航宝胜是一家涉及海缆生产及监测的工程建设公司，年光缆海缆产量达到数万公里，海缆监测项目于2021年6月启动，2021年8月末上线运行。此项目将监测对象首次集中到生产中的光纤上，对生产中光纤进行质量损耗、衰减系数，链长，全程损耗等参数的实时监测，保障了光纤产品的出库标准，得到了客户的广泛认可。",
  //       },
  //       {
  //         name: "镇江广电光缆监测",
  //         content:
  //           "镇江广电光缆监测共施工三期。一期涉及省干线及市干线共9条线路，包括丹阳、句容、南京、常州、扬中五个地区及城市。工程于2019年8月完成施工，同月上线。项目上线后，镇江广电有关领导与运维部门的同事对此系统高度满意，更是在2020年1月的一次重大线路故障中及时发现了线路险情，挽回了一次集团损失。鉴于系统的优秀性能与高度精确性，镇江广电有关部门领导主动推进了光缆监测二期与三期的项目进展。二期与三期分别于2020年5月与2020年10月展开，2020年底均完成上线。三期光缆监测项目的运行完成了由主干网到PON网络的全面覆盖，也实现了镇江广电主要经营区域的线路无差别监控管理，极大地提高了有关部门的运维工作效率。",
  //       },
  //     ],
  //   };
  // },
  mounted(){
    let successList = [];
    for (let i = 0; i < Math.ceil(this.successList.length / 2); i++) {
      successList[i] = [this.successList[i * 2]];
      if (i * 2 + 1 > this.successList.length - 1) {
        console.log(i);
      } else {
        successList[i].push(this.successList[i * 2 + 1]);
      }
    }

    this.successList = successList;
  },
  methods: {
    toLink(id) {
      // console.log(id,99999999999999)
      // this.$router.push({
      //   path: "/successfulCases",
      //   query: {
      //     id,
      //     pid: this.pid,
      //   },
      // });
      // 20211215wbh修改为另开窗口打开
      // window.open(
      //   `http://www.huamai.cn/html/huamaihome/#/successfulCases?id=${id}&pid=${this.pid}`
      // );
      let routeData = this.$router.resolve({
        path: "successfulCases", query: { id,pid:this.pid } 
      })
      window.open(routeData.href, '_blank')
    },
  },
};
</script>
<style scoped>
.cases {
  margin-top: 3vw;
  cursor: pointer;
  /* overflow-x: scroll;
        white-space: nowrap; */
}
.cases > div {
  display: inline-block;
  width: 49%;
  /* height: 26vw; */
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  transition: all 1s;
}
.cases > div:nth-child(2n) {
  margin-left: 2%;
}
.casesImg {
  width: 100%;
  height: 14vw;
  background: #0e538a;
}

.cases >>> .el-carousel__container {
  height: 26vw !important;
}
.cases >>> .el-carousel__button {
  background: #385e8f !important;
}
</style>