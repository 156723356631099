export default {
  data() {
    return {
      bannerImage: ''
    }
  },
  async mounted() {
    const data = {
      type: "1",
      banner_sort: this.sort
    }
    this.programmeId ? data.programme_id = this.programmeId : false
    console.log(data)
    const result = await this.$axios.post(
      "/Banner/api/getbannerDetail.do", data
    );
    if (result.data.code === 0) {
      this.bannerImage = result.data.data.banner_image
    }
  },
}