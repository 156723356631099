<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/workIntoHM/1.png')"
        :title="'走 进 华 脉'"
        :links="links"
      ></moduletitle>
      <bar :indexA="1"></bar>
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            margin-top: 3vw;
          "
        >
          社会责任
        </p>
        <p class="underline"></p>
        <p class="text">
          业绩是企业经营结果的最终体现，是评价企业发展最关键的指标。公司把业绩作为体现社会价值、企业价值和员工价值的结合点。通过科学合理的指标考核，形成企业在市场上以业绩论成败，员工在企业中以业绩论奖惩的氛围，激励企业和员工提高工作效率，从而提升公司的整体业绩。
        </p>
        <p class="text">
          以道德和诚信方式开展业务，致力于持续拓展与政府和投资者、员工、客户、合作伙伴、社区与公众、同行业伙伴、社团组织、供应商等的对话，充分尊重利益相关者的合法权利，并与他们积极合作，共同推进公司持续、健康地发展。
        </p>
        <div class="img">
          <img src="../../assets/image/workIntoHM/7.jpg" alt="" />
        </div>
        <p class="text">
          公司坚持把“开拓、创新，为客户提供一流的产品、一流的服务”作为企业经营的理念;自行开发生产出通信电源配电产品，总配线架、光纤配线架、数字配线架配线系列产品、无源光器件产品、FTTX光缆系列产品、无源微波产品、汽车零配件产品等系列产品;已形成以通信、汽车配件产业为主导的产业格局。
        </p>
        <p class="text">
          架设沟通的桥梁，成就民族梦想，华脉人愿同各界朋友广泛接触合作，携手共创美好的明天。
        </p>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/bar.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      links: [],
    };
  },
  methods: {
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "走进华脉",
          url: "/companyProfile",
          query: {},
        },
        {
          name: "社会责任",
          url: "/socialFunctions",
          query: {},
        },
      ];
    },
  },
  mounted() {
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 3px solid #124b99;
  width: 5.6vw;
  margin: 6px auto 0 auto;
}
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 30px;
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.text {
  line-height: 30px;
  width: 100%;
  text-align: justify;
  margin-top: 2vw;
  font-size: 16px;
  color: #333;
}
.img {
  width: 100%;
  /* height: 20vw; */
  background: #0e538a;
  margin-top: 3vw;
}
</style>