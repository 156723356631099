<template>
  <div id="app">
    <router-view></router-view>
    <float></float>
  </div>
</template>

<script>
import float from '../src/components/LeaveMessage.vue'
export default {
  components:{float},
  name: 'App',
  created(){
    window.scrollTo(0, 0);
  },
  methods:{}
}
</script>

<style>
  body{
    background: #F6F6F6;
  }
  #app{
    min-width: 750px;
    background: #F6F6F6;
    position: absolute;
    top: 0;
    left: 0;
  }
  .swiper-slide img {
    width: 100%;
  }
  *{touch-action: none;} 
</style>
