<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="bannerImage"
        :title="'产 品 及 解 决 方 案'"
        :links="links"
      ></moduletitle>
      <bar :indexA="productIndex" :productList="productList"></bar>
      <div class="message">
        <div class="introduce">
          <div class="introduceLeft">
            <img
              v-if="mainLeftImage"
              :src="mainLeftImage"
              style="height: 20vw"
            />
          </div>
          <div class="introduceRight">
            <div
              style="
                position: absolute;
                width: 100%;
                height: 10px;
                top: 0;
                left: 0;
                background: #083875;
              "
            ></div>
            <div style="overflow-y: auto; height: 16vw; margin-top: 1vw">
              <p class="introduceTitle">
                <span>{{ details.solutionName }}</span>
              </p>
              <p style="line-height: 2.2vw; font-size: 16px">
                {{ details.solutionBrief }}
              </p>
            </div>
            <div
              style="
                color: #fff;
                background: #0e538a;
                width: 10vw;
                height: 2vw;
                line-height: 2vw;
                text-align: center;
                margin: 0 auto;
                margin-top: 3vw;
                position: absolute;
                bottom: 0;
                left: 0;
              "
              @click="toUrl('/contactUs')"
            >
              联系我们
              <span
                style="
                  font-size: 0.7vw;
                  padding-left: 0.2vw;
                  vertical-align: top;
                "
                >▶</span
              >
            </div>
          </div>
        </div>

        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            margin-top: 3vw;
          "
        >
          解决方案
        </p>
        <p class="underline"></p>
        <div class="solution">
          <div
            @click="toProductDetail(item.id)"
            @mouseenter="onEnter(index)"
            @mouseleave="onLeave"
            v-for="(item, index) in details.sonList"
            :key="index"
            :style="{
              background: 'url(' + item.imageName + ') no-repeat center/cover',
            }"
          >
            <p>{{ item.solutionName }}</p>
            <span
              class="hoverOpt"
              :class="
                hoverActive != null
                  ? hoverActive == index
                    ? 'hoverActive'
                    : 'otherActive'
                  : ''
              "
            ></span>
          </div>
        </div>
        <p
          style="
            text-align: center;
            font-size: 36px;
            color: #083875;
            font-weight: bold;
            margin-top: 3vw;
          "
          v-if="caseList && caseList.length > 0"
        >
          成功案例
        </p>
        <p class="underline" v-if="caseList && caseList.length > 0"></p>

        <div class="cases" v-if="caseList && caseList.length">
          <el-carousel style="width: 100%; border: none; padding-bottom: 30px">
            <el-carousel-item
              v-for="(item, index) in caseList"
              :key="index"
              style="display: flex; justify-content: space-between"
            >
              <div
                v-for="(i, ind) in item"
                :key="ind"
                style="width: 48%; border: 1px solid #dcdcdc"
                @click="
                  toUrl(
                    '/successfulCases?id=' + i.id + '&pid=' + $route.query.pid
                  )
                "
              >
                <div
                  class="casesImg"
                  :style="{
                    background: 'url(' + i.imgurl + ') no-repeat center/cover',
                  }"
                ></div>
                <p
                  style="
                    font-size: 23px;
                    font-weight: bold;
                    text-align: center;
                    padding: 1vw 0;
                  "
                >
                  {{ i.title }}
                </p>
                <p
                  style="
                    text-align: left;
                    padding: 0 1vw;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    font-size: 15px;
                    color: #666;
                  "
                >
                  {{ i.detail }}
                </p>
                <div
                  style="
                    text-align: right;
                    padding-right: 1.4vw;
                    margin-top: 1.3vw;
                  "
                >
                  <span
                    style="
                      display: inline-block;
                      width: 8vw;
                      height: 2vw;
                      line-height: 2vw;
                      text-align: center;
                      border: 1px solid #dcdcdc;
                      color: #0d3c78;
                    "
                    >了解详情></span
                  >
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/category.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      // list: [
      //   "光缆监测",
      //   "解决方案2",
      //   "解决方案3",
      //   "解决方案4",
      //   "解决方案5",
      //   "解决方案6",
      // ],
      cases: [
        {
          name: "扬州中航宝胜海缆监测",
          content:
            "扬州中航宝胜是一家涉及海缆生产及监测的工程建设公司，年光缆海缆产量达到数万公里，海缆监测项目于2021年6月启动，2021年8月末上线运行。此项目将监测对象首次集中到生产中的光纤上，对生产中光纤进行质量损耗、衰减系数，链长，全程损耗等参数的实时监测，保障了光纤产品的出库标准，得到了客户的广泛认可。",
        },
        {
          name: "镇江广电光缆监测",
          content:
            "镇江广电光缆监测共施工三期。一期涉及省干线及市干线共9条线路，包括丹阳、句容、南京、常州、扬中五个地区及城市。工程于2019年8月完成施工，同月上线。项目上线后，镇江广电有关领导与运维部门的同事对此系统高度满意，更是在2020年1月的一次重大线路故障中及时发现了线路险情，挽回了一次集团损失。鉴于系统的优秀性能与高度精确性，镇江广电有关部门领导主动推进了光缆监测二期与三期的项目进展。二期与三期分别于2020年5月与2020年10月展开，2020年底均完成上线。三期光缆监测项目的运行完成了由主干网到PON网络的全面覆盖，也实现了镇江广电主要经营区域的线路无差别监控管理，极大地提高了有关部门的运维工作效率。",
        },
      ],
      details: "",
      caseList: "",
      newCaseList: null, //成功案例列表
      caseIndexA: 0,
      productList: [],
      pid: "",
      productIndex: 0,
      bannerImage: "",
      mainLeftImage: null, //左侧主图片
      links: [],
      hoverActive: null, //解决方案当前鼠标悬停图片下标
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    this.pid = this.$route.query.pid;
  },
  watch: {
    $route: {
      handler() {
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    // 获取列表
    getList() {
      this.$axios
        .get("/Programme/api/selectFirst.do")
        .then((res) => {
          //   console.log(res.data)
          this.productList = res.data.data;
          const links = [
            {
              name: "首页",
              url: "/index",
              query: {},
            },
            {
              name: "产品及解决方案",
              url: "/productCenter",
              query: { pid: res.data.data[0].id },
            },
          ];
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].id == this.$route.query.pid) {
              this.productIndex = i;
              links.push({
                name: res.data.data[i].solutionName,
                url: "/productCenter",
                query: { pid: res.data.data[i].id },
              });
              this.links = links;
            }
          }
          this.queryList();
          this.getSucInfo(); //获取成功案例接口
          this.getBanner();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toUrl(url) {
      // this.$router.push({ path: url });
      let routeData = this.$router.resolve({
        path: url
      })
      window.open(routeData.href, '_blank')
    },
    toProductDetail(id) {
      if (id != 0) {
        sessionStorage.setItem("productIndex", this.productIndex);

        // this.$router.push({ path: "productDetail", query: { id } });
        let routeData = this.$router.resolve({
          path: "productDetail", query: { id } 
        })
        window.open(routeData.href, '_blank')

      } else {
        this.$router.push({ path: "contactUs" });
      }
    },
    // 解决方案鼠标移入
    onEnter(ind) {
      // console.log(ind,"鼠标移入")
      this.hoverActive = ind;
    },
    // 解决方案鼠标移出
    onLeave() {
      // console.log(ind,"鼠标移出");
      this.hoverActive = null;
    },
    getDetails(v) {
      console.log(v);
      this.details = v;
      let caseList = [];
      for (let i = 0; i < Math.ceil(v.caseList.length / 2); i++) {
        caseList[i] = [v.caseList[i * 2]];
        if (i * 2 + 1 > v.caseList.length - 1) {
          console.log(i);
        } else {
          caseList[i].push(v.caseList[i * 2 + 1]);
        }
      }

      this.caseList = caseList;
    },

    queryList() {
      this.$axios
        .get("/Programme/api/selectByPid.do", {
          params: { id: this.$route.query.pid },
        })
        .then((res) => {
          // if (this.$route.query.pid == 5 || this.$route.query.pid == 6) {
          //   //数据中心和集成创新单独加个联系我们的链接
          //   res.data.data.sonList.push({
          //     id: 0,
          //     imageName: require("../../assets/image/contact-img.jpg"),
          //     solutionName: "华脉科技竭诚为您服务",
          //   });
          // }
          this.details = res.data.data;
          // let details = res.data.data;
          this.mainLeftImage = res.data.data.imageName; //主图图片
          // let caseList = [];
          // if (!details.caseList) {
          //   this.caseList = [];
          //   return;
          // }
          // for (let i = 0; i < Math.ceil(details.caseList.length / 2); i++) {
          //   caseList[i] = [details.caseList[i * 2]];
          //   if (i * 2 + 1 > details.caseList.length - 1) {
          //     console.log(i);
          //   } else {
          //     caseList[i].push(details.caseList[i * 2 + 1]);
          //   }
          // }
          // this.caseList = caseList;
          // console.log(this.caseList, "成功案例0000000");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 成功案例接口
    getSucInfo() {
      // console.log(this.$route.query.pid,"成功案例id")
      this.$axios
        .get("/Programme/api/getProgrammeCase.do", {
          params: { id: this.$route.query.pid },
        })
        .then((res) => {
          if (res.data.code == 0) {
            let newData = res.data.data;
            let caseList = [];
            newData.map((el, index) => {
              if (caseList[parseInt(index / 2)]) {
                caseList[parseInt(index / 2)].push(el);
              } else {
                caseList[parseInt(index / 2)] = [];
                caseList[parseInt(index / 2)].push(el);
              }
            });

            this.caseList = caseList;
          }
        });
    },
    //查询banner
    async getBanner() {
      const programId = this.$route.query.pid;
      const result = await this.$axios.post("/Banner/api/getbannerDetail.do", {
        type: "1",
        banner_sort: "2",
        programme_id: programId + "",
      });
      if (result.data.code === 0) {
        this.bannerImage = result.data.data.banner_image;
      }
    },
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 3px solid #124b99;
  width: 5.6vw;
  margin: 6px auto 0 auto;
}
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 24px;
}
.home {
  background: #fff;
  min-height: 100vh;
}
.message {
  width: 65vw;
  min-width: 960px;
  margin: 0 auto;
  position: relative;
}
.introduce {
  height: 20vw;
  width: 100%;
  margin-top: 3vw;
}
.introduceLeft {
  width: 45%;
  height: 20vw;
  /* background: #C9C9C9; */
  float: left;
  /* background: url("../../assets/image/chanpin01/1.png") no-repeat center/cover; */
}
.introduceRight {
  width: 50%;
  height: 20vw;
  /* background: #c9c9c9; */
  float: right;
  position: relative;
}
.introduceTitle {
  width: 100%;
  padding: 15px 0;
}
.introduceTitle > span {
  display: inline-block;
  line-height: 2vw;
  font-size: 1.5vw;
  color: #002c6d;
  position: relative;
  font-weight: bold;
}
.introduceTitle > span > i {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #000;
}
.solution {
  margin-top: 2vw;
}
.solution > div {
  display: inline-block;
  width: 32.5%;
  height: 13vw;
  background: #aaaaaa;
  margin-bottom: 0.8vw;
  position: relative;
}

.solution .hoverOpt {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 1s;
}
.hoverActive {
  background: rgba(48, 135, 197, 0.5);
}
.otherActive {
  background: rgba(0, 0, 0, 0.5);
}
.solution > div:hover .hoverActive,
.solution > div .otherActive {
  opacity: 1;
}
.solution > div:nth-child(3n-1) {
  margin: 0 1.25% 0.8vw 1.25%;
}
.solution > div > p {
  position: absolute;
  line-height: 2vw;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: #fff;
  bottom: 0;
  text-align: center;
}
.cases {
  margin-top: 3vw;
  cursor: pointer;
  /* overflow-x: scroll;
        white-space: nowrap; */
}
.cases > div {
  display: inline-block;
  width: 49%;
  /* height: 26vw; */
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  transition: all 1s;
}
.cases > div:nth-child(2n) {
  margin-left: 2%;
}
.casesImg {
  width: 100%;
  height: 14vw;
  background: #0e538a;
}
.lunboTab02 {
  text-align: center;
  margin: 3vw 0 1vw 0;
  cursor: pointer;
}
.lunboTab02 > div {
  display: inline-block;
  width: 3vw;
  border-radius: 0.5vw;
  height: 0.5vw;
  background: #bdc8d7;
  margin: 0 0.3vw;
}
.lunboTabA02 {
  background: #385e8f !important;
}
.cases >>> .el-carousel__container {
  height: 26vw !important;
}
.cases >>> .el-carousel__button {
  background: #385e8f !important;
}
</style>
