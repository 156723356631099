import { render, staticRenderFns } from "./honor.vue?vue&type=template&id=c5f28f2a&scoped=true&"
import script from "./honor.vue?vue&type=script&lang=js&"
export * from "./honor.vue?vue&type=script&lang=js&"
import style0 from "./honor.vue?vue&type=style&index=0&id=c5f28f2a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5f28f2a",
  null
  
)

export default component.exports