<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="bannerImage"
        :title="'产 业 大 厦'"
        :links="links"
      ></moduletitle>
      <div class="message">
        <div class="introduce">
          <div class="introduceLeft">
            <img src="../../assets/image/zhaoshang02.png" alt="" />
          </div>
          <div class="introduceRight">
            <p style="font-size: 26px; font-weight: bold; color: #0276c2">
              华脉国际广场
            </p>
            <p style="line-height: 30px; text-indent: 2em">
              由南京华脉科技股份有限公司投资建设，由南京华脉众创服务有限公司全方位运营，倾力打造的面向物联网及互联网领域企业的专业科技型载体。
            </p>
            <p style="line-height: 30px; text-indent: 2em">
              地址位于南京市江宁区丰泽路66号，办公的面积近8万平方，充分满足不同规模企业需求；交通便利，距离主城区商业中心、交通枢纽南京南站，禄口机场仅半小时，距宁杭高速口仅5分钟；整合生态办公、商务酒店、环境美食、文体健身等体验式消费场所，周围配套设施完善。
            </p>
            <p style="line-height: 30px; text-indent: 2em">
              华脉国际广场向所有业态企业提供从开放办公空间，产业融合、产品构建、团队服务、市场开拓等全方位的创业服务解决方案。以创新与创业相结合、线上与线下相结合的方式，以专业化服务推动企业应用新技术、开发新产品、开拓新市场、培育新业态，为各企业提供便利化、全要素的开放式综合服务平台。
            </p>
            <p style="line-height: 30px; text-indent: 2em">
              这里是一个开放共享的服务空间，是集聚创新种子和土壤的“生息之地“，是来自社会各地的创客、创业者、创新者等一流人才的共同家园。这里接纳年轻、包容失败、锐意创新，不断为社会创造价值。
            </p>
            <p style="line-height: 30px; text-indent: 2em">
              华脉国际广场将通过搭建创新创业孵化平台，有效激励技术创新主体和创新服务主体互动，高度集聚技术、人才等创新创业要素，促进科技成果产业化，完善载体科技创新体系，服务于社会。
            </p>
          </div>
        </div>

        <div style="position: relative; margin-top: 30px; color: #fff">
          <img src="../../assets/image/zhaoshang.png" alt="" />
          <div
            style="
              position: absolute;
              top: 50%;
              left: 0;
              transform:translateY(-50%);
              line-height: 1.5vw;
              background-color:rgba(47,97,165,0.8);
              padding:1vw 2vw;
            "
          >
            <p>联系人：胥渊</p>
            <p>联系电话：13813856812</p>
            <p>邮箱：wangzhuxian@huamai.com</p>
            <p>地址：江苏省南京市江宁区丰泽路66号华脉国际广场</p>
            <div
              style="
                width: 15vw;
                height: 3vw;
                line-height: 3vw;
                background: #ffffff;
                color: #333;
                font-size: 16px;
                text-align: center;
                margin-top: 1vw;
                cursor: pointer;
              "
              @click="toLink('/contactUs')"
            >
              查看更多联系方式>>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bannerMixin from "../../mixin/bannerMixin";
export default {
  components: { tapbar, bottombar, moduletitle },
  mixins: [bannerMixin],
  data() {
    return {
      isTop: false,
      sort: "6",
      links: [],
    };
  },
  mounted() {
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
  },
  methods: {
    toLink(url) {
      localStorage.setItem("topBarA", 3);
      this.$router.push({ path: url });
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "产业大厦",
          url: "/merchants",
          query: {},
        },
      ];
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 30px;
}
.home {
  background: #fff;
  min-height: 100vh;
}
.message {
  width: 65vw;
  min-width: 996px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.graphic {
  width: 100%;
  height: 20vw;
  margin-top: 2vw;
  font-size: 1.1vw;
  /* background: red; */
}
.graphic > div {
  display: inline-block;
  vertical-align: top;
}
.graphicTxt {
  width: 53%;
  height: 100%;
}
.graphicImg {
  width: 47%;
  height: 100%;
  /* background: #C9C9C9; */
}
.graphicTxt > p {
  line-height: 2vw;
  width: 90%;
  text-align: justify;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.text {
  line-height: 2vw;
  width: 100%;
  text-align: justify;
  margin-top: 2vw;
  font-size: 1.1vw;
}
.introduce {
  height: 15vw;
  width: 100%;
  margin-top: 3vw;
}
.introduceLeft {
  width: 40%;
  height: 100%;
  display: inline-block;
}
.introduceLeft > img {
  width: 100%;
  height: 100%;
}
.introduceRight {
  width: 55%;
  height: 100%;
  /* background: #c9c9c9; */
  position: relative;
  float: right;
  overflow-y: auto;
}
.introduceTitle {
  width: 100%;
  padding: 2vw 0;
}
.introduceTitle > span {
  display: inline-block;
  line-height: 2vw;
  font-size: 1.2vw;
  position: relative;
}
.introduceTitle > span > i {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #000;
}
</style>