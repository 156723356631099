import Vue from 'vue'
import App from './App.vue'
import './assets/css/base.css'
import router from './router/router'
import scroll from 'vue-seamless-scroll'
import VueSwiper from 'vue-awesome-swiper'
import axios from 'axios'
Vue.use(VueSwiper)

Vue.use(scroll)
//线上
axios.defaults.baseURL = 'http://www.huamai.cn/huamai_gateway'
//本地
// axios.defaults.baseURL = '/'
Vue.prototype.$axios = axios
Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.prototype.httpLink = ''
// Vue.prototype.httpLink = 'https://pufa.huamaihome.com/pufa_sign/'
Vue.prototype.imgLink = 'https://image.huamaihome.com/pufa_sign/'

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')