<template>
  <div class="home" v-loading="loading">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/banner/1.png')"
        :title="''"
        :links="links"
      ></moduletitle>
      <bar :indexA="null" :productList="productList"></bar>
      <div class="message">
        <div class="introduce">
          <span class="introduce-img" :style="{backgroundImage:'url('+info.img+')'}">
          </span>
          <!-- <img
            :src="info.img"
            alt=""
            style="width: 20vw; border: 1px solid #aaa; margin: 0 auto"
          /> -->
          <div class="introduceTitle">
            <span>{{ info.productname }}<i></i></span>
          </div>
          <div class="messageTxt" style="text-align: left">
            <span v-if="info.describe != ''" style="font-weight: bold"
              >描述：</span
            >{{ info.describe }}
          </div>
          <div class="messageTxt" style="text-align: left">
            <span v-if="info.point != ''" style="font-weight: bold">特点：</span
            >{{ info.point }}
          </div>
        </div>

        <!-- <p class="messageTitle">产品特点 <span></span></p>
      <div class="messageTxt">高动态范围、高水平分辨率<br /></div>

      <p class="messageTitle">产品图片及规格参数 <span></span></p>
      <div class="messageImgList">
        <img
          src="../../assets/image/chanpin01/13.png"
          alt=""
          style="width: 50%; border: 1px solid #aaa; margin: 0 auto"
        />
        <img
          src="../../assets/image/chanpin01/14.png"
          alt=""
          style="width: 70%; border: 1px solid #aaa; margin: 0 auto"
        />
      </div> -->
        <div v-html="info.summary"></div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/category.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      loading:false,
      isTop: false,
      productList: [],
      links: [],
      info: {
        summary: "",
      },
    };
  },
  methods: {
    // 获取列表
    getList() {
      this.$axios.get("/Programme/api/selectFirst.do").then((res) => {
        //   console.log(res.data)
        this.productList = res.data.data;
        const links = [
          {
            name: "首页",
            url: "/index",
            query: {},
          },
          {
            name: "产品及解决方案",
            url: "/productCenter",
            query: { pid: res.data.data[0].id },
          },
        ];
        if (this.$route.query.pid) {
          const parent = this.productList.find((item) => {
            return item.id == this.$route.query.pid;
          });
          if (parent) {
            links.push({
              name: parent.solutionName,
              url: "/productCenter",
              query: { pid: parent.id },
            });
          }
          this.links = links;
        }
      });
    },
    async getDetail() {
      const result = await this.$axios.get("/Product/api/getProductById.do", {
        params: {
          id: this.$route.query.id,
        },
      });
      console.log(result);
      if (result.data.code === 0) {
        this.info = result.data.data;
      }
    },
  },
  mounted() {
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    this.getList();
    this.getDetail();
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 24px;
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  width: 65vw;
  min-width: 960px;
  margin: 0 auto;
  position: relative;
}
.introduce {
  /* height: 20vw; */
  width: 100%;
  text-align: center;
  margin-top: 3vw;
}
.introduce-img{
  display:inline-block;
  width:20vw;
  height:20vw;
  border: 1px solid #aaa;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.introduceLeft {
  width: 40%;
  height: 20vw;
  background: #c9c9c9;
  float: left;
}
.introduceRight {
  width: 55%;
  height: 20vw;
  /* background: #c9c9c9; */
  float: right;
}
.introduceTitle {
  width: 100%;
  padding: 2vw 0;
}
.introduceTitle > span {
  display: inline-block;
  line-height: 50px;
  font-size: 20px;
  position: relative;
}
.introduceTitle > span > i {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #000;
}

.messageTitle {
  font-size: 24px;
  color: #083875;
  font-weight: bold;
  position: relative;
  padding-left: 1vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
}
.messageTitle > span {
  position: absolute;
  width: 5px;
  height: 24px;
  background: #083875;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.messageTxt {
  line-height: 30px;
  font-size: 16px;
  /* font-weight: bold; */
}
.messageImgList > div {
  width: 24%;
  height: 20vw;
  background: #0e538a;
  display: inline-block;
  margin-left: 1.3%;
  position: relative;
}
.messageImgList > div:first-child {
  margin-left: 0;
}
.messageImgList > div > div > p {
  position: absolute;
  width: 100%;
  line-height: 2vw;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 1vw;
  color: #fff;
}
</style>