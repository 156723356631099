<template>
  <div :class="isTop ? 'topA' : 'top'">
    <img
      src="../assets/image/logo.png"
      alt=""
      style="height: 100%; width: auto"
      @click="goIndex"
    />
    <div class="topList">
      <span
        v-for="(item, index) in topList"
        :key="index"
        @mouseover="intoTab(index)"
        @click="toLink(item.url, index)"
      >
        {{ item.name }}
        <span class="span01"></span>
        <span
          class="span02"
          :style="{
            opacity: topBarA == item.url ? '1!important' : '0!important',
          }"
        ></span>
      </span>
    </div>
    <!-- 语言 -->
    <div class="language" @click="toEnglish">
      <img src="../assets/image/earth.png" alt="" />
      <span>EN</span>
      <img src="../assets/image/down.png" alt="" />
    </div>
    <!-- 搜索 -->
    <div class="search">
      <img src="../assets/image/search.png" alt="" @click="toSearch" />
      <input
        type="text"
        class="searchInput"
        placeholder="请输入关键字搜索"
        v-model="searchValue"
        @keyup.enter="toSearch"
      />
    </div>

    <!-- 走进华脉展开 -->
    <div v-if="isHM" :class="isTop ? 'zoujinA' : 'zoujin'" @mouseleave="outTab">
      <span
        v-for="(item, index) in HMlist"
        :key="index"
        @click="toLink02(item.url)"
        >{{ item.name }}</span
      >
    </div>

    <!-- 产品及解决方案中心展开 -->
    <div
      v-if="chanpin"
      :class="isTop ? 'chanpinA' : 'chanpin'"
      @mouseleave="outTab"
    >
      <div class="chanpinLeft">
        <div
          class="chanpinLeftDiv"
          v-for="(item, index) in productList"
          :key="index"
        >
          <p style="cursor: pointer" @click="toProductCenter(item.id)">
            {{ item.solutionName }}
          </p>
          <div
            v-for="(item02, index02) in item.sonList"
            :key="index02"
            @click="handlerMenu(item02)"
            :style="{
              color:
                !item02.sonList || item02.sonList.length === 0 ? '#555' : '',
            }"
          >
            {{ item02.solutionName }}
            <div v-if="item02.sonList && item02.show">
              <p
                v-for="(item03, index03) in item02.sonList"
                :key="index03"
                style="font-size: 0.8vw; color: #a6a7a9; margin-bottom: 8px"
                @click.stop="handlerMenu(item03)"
              >
                {{ item03.solutionName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "topBar",
  props: ["isTop"],
  data() {
    return {
      index01A: null,
      index02A: null,
      topList: [
        { name: "走进华脉", url: "/companyProfile.html" },
        { name: "产品及解决方案", url: "/productCenter" },
        { name: "投资者关系", url: "/investorsInfo" },
        { name: "联系我们", url: "/contactUs" },
        { name: "招贤纳士", url: "/recruitment" },
        { name: "产业大厦", url: "/merchants" },
      ], //导航栏
      HMlist: [
        { name: "公司简介", url: "/companyProfile" },
        // { name: "组织架构", url: "/organizationalStructure" },
        { name: "社会责任", url: "/socialFunctions" },
        { name: "资质荣誉", url: "/honor" },
        { name: "新闻资讯", url: "/newsInfo" },
        { name: "公司视频", url: "/companyVideo" },
      ], //走进华脉List
      isHM: false, //是否选择走进华脉模组
      chanpin: false, //是否选择产品及解决方案模组
      productList: [
        {
          name: "通信",
          list: [
            { name: "解决方案1", url: "", list: "" },
            { name: "解决方案2", url: "" },
            { name: "解决方案3", url: "" },
            { name: "解决方案4", url: "" },
            { name: "解决方案5", url: "" },
          ],
        },
        {
          name: "数据中心",
          list: [
            {
              name: "解决方案1",
              url: "",
              list: [
                { name: "OTDR（测试设备）", url: "" },
                { name: "OSW（光开关）", url: "" },
                { name: "OPM（光功率）", url: "" },
                { name: "WDM（合波器）", url: "" },
                { name: "FILTER（滤波器）", url: "" },
                { name: "OLP（光保护）", url: "" },
                { name: "OS（光源）", url: "" },
              ],
            },
            { name: "解决方案2", url: "" },
            { name: "解决方案3", url: "" },
            { name: "解决方案4", url: "" },
            { name: "解决方案5", url: "" },
          ],
        },
        {
          name: "集成创新应用",
          list: [
            {
              name: "光缆监测",
              url: "",
              list: [
                { name: "OTDR（测试设备）", url: "" },
                { name: "OSW（光开关）", url: "" },
                { name: "OPM（光功率）", url: "" },
                { name: "WDM（合波器）", url: "" },
                { name: "FILTER（滤波器）", url: "" },
                { name: "OLP（光保护）", url: "" },
                { name: "OS（光源）", url: "" },
              ],
            },
            { name: "解决方案2", url: "" },
            { name: "解决方案3", url: "" },
            { name: "解决方案4", url: "" },
            { name: "解决方案5", url: "" },
          ],
        },
        {
          name: "信息增值服务",
          list: [
            {
              name: "解决方案1",
              url: "",
              list: [
                { name: "OTDR（测试设备）", url: "" },
                { name: "OSW（光开关）", url: "" },
                { name: "OPM（光功率）", url: "" },
                { name: "WDM（合波器）", url: "" },
                { name: "FILTER（滤波器）", url: "" },
                { name: "OLP（光保护）", url: "" },
                { name: "OS（光源）", url: "" },
              ],
            },
            { name: "解决方案2", url: "" },
            { name: "解决方案3", url: "" },
            { name: "解决方案4", url: "" },
            { name: "解决方案5", url: "" },
          ],
        },
      ], //产品中心导航
      planList: [
        {
          name: "无线覆盖",
          list: [
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
          ],
        },
        {
          name: "智慧物联",
          list: [
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
          ],
        },
        {
          name: "软件开发",
          list: [
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
          ],
        },
        {
          name: "多媒体解决方案",
          list: [
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
            { name: "光器件及模块", url: "" },
          ],
        },
      ], //方案中心导航
      topBarA: null,
      searchValue: "",
    };
  },
  mounted() {
    this.topBarA = localStorage.getItem("topBarA");
    this.getProductList();
  },
  methods: {
    // 回到首页
    goIndex() {
      // console.log("回到首页-------------",window.location)
      this.isHM = false;
      if(window.location.hash !== "#/index"){
        this.$router.push("/index");
      }
      // if (localStorage.getItem("topBarA") != "/index") {
      //   this.$router.push("/index");
      //   localStorage.setItem("topBarA", "/index");
      // }
    },
    // 鼠标移入tabber
    intoTab(index) {
      if (index == 0) {
        this.isHM = true;
        this.chanpin = false;
      } else if (index == 1) {
        this.isHM = false;
        this.chanpin = true;
      } else {
        this.isHM = false;
        this.chanpin = false;
      }
    },
    // 鼠标移出tabberadius
    outTab() {
      this.isHM = false;
      this.chanpin = false;
    },
    async toLink(url, index) {
      this.chanpin = false;
      // if (localStorage.getItem("topBarA") == url) return; //当前路由不可重复点击
      localStorage.setItem("topBarA", url);
      if (index === 1) {
        const result = await this.$axios.get("/Programme/api/selectFirst.do");
        this.$router.push({
          path: url,
          query: { pid: result.data.data[0].id },
        });
      } else {
        this.$router.push({ path: url });
      }
    },
    toLink02(url) {
      this.isHM = false;
      if (this.$route.path != url) {
        localStorage.setItem("topBarA", this.topList[0].url);
        this.$router.push({ path: url });
      }
    },
    //查询解决方案菜单
    async getProductList() {
      const result = await this.$axios.get("/Programme/api/getSolutionTree.do");
      if (result.data.code === 0) {
        result.data.data.map((el) => {
          if (el.sonList) {
            el.sonList.map((i) => {
              i.show = true;
            });
          }
        });
        this.productList = result.data.data;
      }
    },
    handlerMenu(item) {
      setTimeout(() => {
        localStorage.setItem("topBarA", this.$route.path);
        this.topBarA = this.$route.path;
      });
      if (item.sonList) {
        // 当前导航存在子列表不可点击直接关闭导航栏，只能展开/收起
        if (item.show != undefined) {
          item.show = !item.show;
        }
      } else {
        // 不存在子导航列表点击直接关闭
        this.chanpin = false;
      }
      if (!item.sonList || item.sonList.length === 0) {
        // 子路由重复处理
        if (this.$route.query.id != item.id) {
          this.$router.push({ path: "productDetail", query: { id: item.id } });
        }
      }
    },
    toEnglish() {
      window.open("https://www.huamai.com/");
    },
    toSearch() {
      // 判断当前是否为搜索页(阻止重复路由)
      setTimeout(() => {
        localStorage.setItem("topBarA", this.$route.path);
      });
      this.$router.push()
      if (this.$route.path == "/search") {
        let val = this.searchValue;
        this.$emit("to-search", val);
      } else {
        this.$router.push({path: '/search?searchValue=' + this.searchValue});
        // this.$router.push({
        //   name: "search",
        //   params: { searchValue: this.searchValue },
        // });
      }
    },
    toProductCenter(id) {
      this.$router.push({ path: "/productCenter", query: { pid: id } });
    },
  },
};
</script>


<style scoped>
.top {
  width: 92.5%;
  min-width: 1024px;
  height: 4.4vw;
  background: #fff;
  position: fixed;
  top: 1.8vw;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 2vw;
  z-index: 100;
}
.topA {
  width: 96%;
  min-width: 1024px;
  height: 4.4vw;
  background: #fff;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 2vw;
  z-index: 999;
  transition: all 0.2s;
  box-shadow: 1px 1px 10px #999;
}

.topList {
  display: inline-block;
  color: #000000;
  margin-left: 3vw;
  font-size: 16px;
  height: 4.4vw;
  line-height: 4.4vw;
}
.topList > span {
  padding: 0 2vw;
  /* font-weight: bold; */
  /* -webkit-text-stroke:.5px #999; */
  display: inline-block;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.span01,
.span02 {
  width: 2vw;
  height: 0.3vw;
  background: #2d5589;
  position: absolute;
  bottom: 0.9vw;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.1s;
}
.topList > span:hover .span01 {
  opacity: 1;
}
.language {
  cursor: pointer;
}
.language,
.search {
  display: inline-block;
  margin-left: 1vw;
  height: 100%;
  vertical-align: top;
  float: right;
  position: relative;
}
.language > img,
.search > img {
  width: auto;
  height: 100%;
  position: relative;
  /* top: -.2vw; */
}
.search:hover .searchInput {
  display: block;
}
.language > span {
  color: #000000;
  font-size: 0.9vw;
  height: 4.4vw;
  line-height: 4.4vw;
  -webkit-text-stroke: 0.5px #999;
}
.searchInput {
  height: 2vw;
  width: 10vw;
  border: 1px solid #aaa;
  /* float: right; */
  border-radius: 0.3vw;
  position: absolute;
  display: none;
  top: 1.1vw;
  left: 0;
  padding: 0 1vw;
  transform: translateX(-100%);
}

.zoujin {
  width: 100%;
  height: 4.4vw;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: -4.4vw;
  left: 0;
}
.zoujin > span,
.zoujinA > span {
  display: inline-block;
  width: 16.6%;
  text-align: center;
  font-size: 15px;
  line-height: 4.4vw;
  cursor: pointer;
}
.zoujin > span:hover {
  color: #be303c;
}
.zoujinA > span:hover {
  color: #be303c;
}
.zoujinA {
  width: 100%;
  height: 4.4vw;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: -4.4vw;
  left: 0;
  box-shadow: 1px 5px 5px 1px #999;
  border-top: 1px solid #eee;
}
.chanpin {
  width: 100%;
  /* height: 4.4vw; */
  background: rgba(255, 255, 255, 1);
  position: absolute;
  padding-bottom: 2vw;
  /* bottom: -100%; */
  /* transform: translateY(100%); */
  left: 0;
  border: 1px solid #aaa;
  box-sizing: border-box;
}
.chanpinA {
  width: 100%;
  /* height: 4.4vw; */
  background: rgba(255, 255, 255, 1);
  position: absolute;
  padding-bottom: 2vw;
  border-top: 1px solid #eee;
  /* bottom: -100%; */
  /* transform: translateY(100%); */
  box-shadow: 1px 5px 5px 0px #aaa;
  left: 0;
}
.chanpinLeft {
  /* height: 10vw; */
  width: 100%;
  display: inline-block;
  vertical-align: top;
  /* padding-left: 3vw; */
}
.chanpinLeftDiv > p {
  font-weight: bold;
  margin-bottom: 1vw;
  margin-top: 1vw;
  font-size: 20px;
}
.chanpinLeftDiv > div {
  color: #c85f68;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 8px;
}
.chanpinLeftDiv > div > p:hover {
  color: #7a91b1;
}
.chanpinLeftDiv {
  width: 20%;
  display: inline-block;
  margin-top: 1vw;
  padding-left: 4vw;
  vertical-align: top;
}
</style>