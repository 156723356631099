<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/workIntoHM/6.png')"
        :title="content.job"
        :subtitle="content.jobType + '-' + content.department"
        :links="links"
      ></moduletitle>
      <!-- <bar :indexA="0"></bar> -->
      <div class="message">
        <p class="job-wrapper">{{ content.job }}</p>
        <p class="createtime-wrapper">发布时间：{{ content.createTime }}</p>
        <p class="messageTitle" v-if="content.responsibilities !== ''">
          岗位职责 <span></span>
        </p>
        <div
          class="messageTxt"
          v-if="content.responsibilities !== ''"
          v-html="content.responsibilities"
        ></div>

        <p class="messageTitle" v-if="content.requirement !== ''">
          岗位要求 <span></span>
        </p>
        <div
          class="messageTxt"
          v-if="content.requirement !== ''"
          v-html="content.requirement"
        ></div>

        <p class="messageTitle">工作地点 <span></span></p>
        <div class="messageTxt">上班地址：{{ content.address }}<br /></div>

        <p class="messageTitle">联系方式 <span></span></p>
        <div class="messageTxt">
          联系人：{{ content.contacts }}<br />
          联系电话：{{ content.mobile }}<br />
          邮箱：{{ content.email }}<br />
        </div>

        <!-- <div class="submit"><span>申请</span><span style="background:#0E538A;color:#fff">点击上传简历</span></div> -->
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
export default {
  components: { tapbar, bottombar, moduletitle },
  data() {
    return {
      isTop: false,
      content: "",
      links: [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "招贤纳士",
          url: "/recruitment",
          query: {},
        },
      ],
    };
  },
  mounted() {
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .get("/recruit/api/getRecruit.do", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.content = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  padding-bottom: 24px;
}
.home {
  background: #fff;
  min-height: 100vh;
}

.message {
  width: 65vw;
  min-width: 960px;
  margin: 0 auto;
  position: relative;
}
.messageTitle {
  font-size: 1.5vw;
  position: relative;
  padding-left: 1vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
}
.messageTitle > span {
  position: absolute;
  width: 0.3vw;
  height: 1vw;
  background: #124b99;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.messageTxt {
  line-height: 1.5vw;
}
.submit {
  margin-top: 2vw;
}
.submit > span {
  height: 2vw;
  display: inline-block;
  line-height: 2vw;
  width: 8vw;
  border: 1px solid #bdbdbd;
  text-align: center;
  box-sizing: border-box;
  margin-right: 2vw;
}
.job-wrapper {
  font-size: 1.5vw;
  margin: 1.4vw 0 0.6vw 0;
}
.createtime-wrapper {
  font-size: 1.2vw;
}
</style>