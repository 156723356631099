import vue from 'vue'
import vueRouter from 'vue-router'
import Index from '../pages/index'
// import top from '../components/moduleTitle.vue' 
import companyProfile from '../pages/walkIntoHM/companyProfile.vue'
import socialFunctions from '../pages/walkIntoHM/socialFunctions.vue'
import organizationalStructure from '../pages/walkIntoHM/organizationalStructure.vue'
import honor from '../pages/walkIntoHM/honor.vue'
import newsInfo from '../pages/walkIntoHM/newsInfo.vue'
import newsInfoDetails from '../pages/walkIntoHM/newsInfoDetails.vue'
import investorsInfo from '../pages/investorsInfo/investorsInfo.vue'
import contactUs from '../pages/contactUs/contactUs.vue'
import companyVideo from '../pages/walkIntoHM/companyVideo.vue'
import recruitment from '../pages/recruitment/recruitment.vue'
import jobDetail from '../pages/recruitment/jobDetail.vue'
import productCenter from '../pages/productCenter/productCenter.vue'
import productDetail from '../pages/productCenter/productDetail.vue'
import successfulCases from '../pages/productCenter/successfulCases.vue'
import product from '../pages/productCenter/product.vue'
import merchants from '../pages/merchants/merchants.vue'
import search from '../pages/search/Search.vue'
vue.use(vueRouter)

// 防止路由重复点击报错
// const originalPush = vueRouter.prototype.push
// vueRouter.prototype.push = function push(location){
//     return originalPush.call(this.addRoute,location).catch(err => err)
// }


const routes = [{
        path: '/index.html',
        component: Index,
        name:"南京华脉科技股份有限公司_股票代码603042"
    },
    {
        path: '/',
        component: Index,
        name:"南京华脉科技股份有限公司_股票代码603042"
    },
    {
        path: '/index',
        component: Index,
        name:"南京华脉科技股份有限公司_股票代码603042"
    },
    {
        path: '/companyProfile', //公司简介
        component: companyProfile,
        name:'公司简介'
    },
    {
        path: '/companyProfile.html', //公司简介
        component: companyProfile,
        name:'公司简介'
    },
    {
        path: '/socialFunctions', //社会职能
        component: socialFunctions,
        name:'社会职能'
    },
    {
        path: '/organizationalStructure', //组织架构
        component: organizationalStructure,
        name:'组织架构'
    },
    {
        path: '/honor', //荣誉资质
        component: honor,
        name:'荣誉资质'
    },
    {
        path: '/newsInfo', //新闻资讯
        component: newsInfo,
        name:'新闻资讯'
    },
    {
        path: '/newsInfoDetails', //新闻资讯
        component: newsInfoDetails,
        name:'新闻资讯'
    },
    {
        path: '/investorsInfo', //投资者关系
        component: investorsInfo,
        name:'投资者关系'
    },
    {
        path: '/contactUs', //联系我们
        component: contactUs,
        name:'联系我们'
    },
    {
        path: '/companyVideo', //公司视频
        component: companyVideo,
        name:'公司视频'
    },
    {
        path: '/recruitment', //招贤纳士
        component: recruitment,
        name:'招贤纳士'
    },
    {
        path: '/jobDetail', //职位详情
        component: jobDetail,
        name:'职位详情'
    },
    {
        path: '/productCenter', //产品及解决方案中心首页
        component: productCenter,
        name:'产品及解决方案中心首页'
    },
    {
        path: '/productDetail', //解决方案详情
        component: productDetail,
        name: '解决方案详情'
    },
    {
        path: '/successfulCases', //解决方案成功案例
        component: successfulCases,
        name:'解决方案成功案例'
    },
    {
        path: '/product', //产品详情
        component: product,
        name:'产品详情'
    },
    {
        path: '/merchants', //招商入驻
        component: merchants,
        name:'产业大厦'
    },
    {
        path: '/search',
        component: search,
        name: '搜索'
    }
]

const pathnameArr = location.pathname.split('/')
pathnameArr.length = pathnameArr.length - 1
const base = pathnameArr.join('/')

//定义实例
const router = new vueRouter({
    routes,
    mode: 'history',
    base
})
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.documentElement.scrollTop = 0

    if(to.name){
        document.title = to.name
    }

    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})
//导出实例
export default router