<template>
<div>
  <div class="tit">应用场景</div>
  <div v-html="applicationDescription">
    <!-- <p
      style="
        text-align: center;
        font-size: 18px;
        color: #314257;
        padding: 3vw 0 0.5vw 0;
      "
    >
      APPLICATION SCENARIOS
    </p>
    <p
      style="
        text-align: center;
        font-size: 36px;
        color: #083875;
        font-weight: bold;
      "
    >
      应用场景
    </p>
    <p class="text" style="margin-top: 2vw; line-height: 2vw">
      当我们使用无线网络在享受各种乐趣的时候，您可能并未意识到，各个无线节点实际上是通过大量光纤通信网络相互连接。如今，随着5G网络无线接入点的不断拓展，需要越来越多的光纤来满足这些网络日益增长的需求。<br /><br />
      各行各业的光缆消耗量也会与日俱增，光缆的维护与监测便成为了当下与未来亟需解决的重中之重。
    </p>
    <div style="width: 100%; margin-top: 2vw">
      <img src="../../assets/image/chanpin01/10.png" alt="" /> -->
    <!-- </div> -->
  </div>
</div>
</template>
<script>
export default {
  name: "applicationScenarios",
  props: ["applicationDescription"],
};
</script>