<template>
  <div>
    <div class="tit">方案介绍</div>
    <div v-html="solutionDescription">
    <!-- <p style="text-align:center;font-size:18px;color:#314257;padding:3vw 0 .5vw 0">PLAN TO INTRODUCE</p>
        <p style="text-align:center;font-size:36px;color:#083875;font-weight:bold">方案介绍</p>
        <div class="text" style="margin-top:2vw;line-height:2vw">
            <p class="messageTitle">方案优势 <span></span></p>
            1、RFTS型光缆网实时监控系统，将光缆监测、光保护（OLS）、光缆巡检、告警、故障分析、定位、故障管理、线路维护、线路管理有机结合在一起，为光缆网络的安全高效运行提供保障，可对通信光缆进行24小时全天候自动监测，及时准确地报告突发性光缆故障，有效缩短故障历时，及时发现隐含的、尚未但将会造成通讯阻断的潜在故障并进行准确的预警，做到主动维护，防患于未然。<br><br>
            2、RFTS系统采用模块化设计，扩充性强且易于安装维护，适合各种光缆网络进行监测。<br><br>
            3、结合RFTS型光缆网实时监控系统软件功能，提供强大的OTDR光纤实时、在线、自动监测功能、GIS地图辅助资源管理功能，提供多重告警回报方式，为相关部门提供一个有效的光缆网监测和维护的手段，协助管理人员全面掌握光缆网质量状况，大幅提升运维绩效与通讯质量。
            <p class="messageTitle">光缆资源管理功能 <span></span></p>
            <img src="../../assets/image/fajs01.png" alt="">
            <p class="messageTitle">手机APP功能 <span></span></p>
            <img src="../../assets/image/fajs02.png" alt="">
        </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "planAdvantage",
  props: ["solutionDescription"],
};
</script>

<style scoped>
.messageTitle {
  font-size: 24px;
  color: #083875;
  font-weight: bold;
  position: relative;
  padding-left: 1vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
}
.messageTitle > span {
  position: absolute;
  width: 5px;
  height: 24px;
  background: #083875;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

</style>