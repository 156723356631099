<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/workIntoHM/1.png')"
        :title="'走 进 华 脉'"
        :links="links"
      ></moduletitle>
      <bar :indexA="3"></bar>
      <div class="message">
        <p
          style="
            text-align: center;
            font-size: 32px;
            color: #083875;
            font-weight: bold;
            padding: 3vw 0 0.5vw 0;
          "
        >
          {{ title }}
        </p>
        <p
          style="
            text-align: center;
            font-size: 18px;
            color: #314257;
            padding: 0 0 1vw 0;
          "
        >
          {{ createtime[0] }}
        </p>
        <div v-html="content"></div>
        <div class="pageInfo">
          <div style="float:left;" v-if="this.pageInfo.lastId">
            <span class="btn" @click="onSize('lastSize')">上一条</span><br />{{
              this.pageInfo.lastTitle
            }}
          </div>
          <div style="float:right;" v-if="this.pageInfo.nextId">
            <span class="btn" @click="onSize('nextSize')">下一条</span><br />{{
              this.pageInfo.nextTitle
            }}
          </div>
          <div style="clear:both;"></div>
        </div>
      </div>
    </div>
    <bottombar></bottombar>

  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/bar.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      content: "",
      title: "",
      createtime: "",
      links: [],
      pageInfo: {
        currentId: null, //当前条id
        lastId: null, //上一条id
        lastTitle: null, //上一条标题
        nextId: null, //下一条id
        nextTitle: null, //下一条标题
      },
    };
  },
  created() {
    // console.log(this.$route.query.params,"0000")
  },
  mounted() {
    this.pageInfo = JSON.parse(this.$route.query.params);
    this.getLinks();
    let that = this;
    window.onscroll = function() {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };
    let params = {
      id: this.pageInfo.currentId,
      lastId: this.pageInfo.lastId, //上一条id
      lastTitle: this.pageInfo.lastTitle, //上一条标题
      nextId: this.pageInfo.nextId, //下一条id
      nextTitle: this.pageInfo.nextTitle, //下一条标题
    };
    this.getInfo(params);
  },
  methods: {
    getInfo(params) {
      // console.log(params, "0000000");
      this.$axios
        .get("/News/api/getNewsDetail.do", {
          params: params,
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.content = res.data.data.content.replace(
              "../..",
              "http://192.168.200.142:8080/"
            );
            this.createtime = res.data.data.createtime.split(" ");
            this.title = res.data.data.title;
            this.pageInfo = {
              currentId: res.data.data.id,
              lastId: res.data.data.lastId, //上一条id
              lastTitle: res.data.data.lastTitle, //上一条标题
              nextId: res.data.data.nextId, //下一条id
              nextTitle: res.data.data.nextTitle, //下一条标题
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "走进华脉",
          url: "/companyProfile",
          query: {},
        },
        {
          name: "新闻资讯",
          url: "/newsInfo",
          query: {},
        },
      ];
    },
    // 上一条/下一条
    onSize(flag) {
      // console.log(flag,this.pageInfo,"当前条")
      if (flag == "lastSize") {
        // 上一条
        let params = {
          currentId: this.pageInfo.lastId,
        };
        // console.log(params,"上一条")
        // this.getInfo(params);
        this.$router.push({ path: "/newsInfoDetails", query: { params: JSON.stringify(params) } });
        this.pageInfo = JSON.parse(this.$route.query.params);
        this.getLinks();
        let that = this;
        window.onscroll = function() {
          let toTop = document.documentElement.scrollTop || document.body.scrollTop;
          if (toTop > 300) {
            that.isTop = true;
          } else {
            that.isTop = false;
          }
        };
        let params02 = {
          id: this.pageInfo.currentId,
          lastId: this.pageInfo.lastId, //上一条id
          lastTitle: this.pageInfo.lastTitle, //上一条标题
          nextId: this.pageInfo.nextId, //下一条id
          nextTitle: this.pageInfo.nextTitle, //下一条标题
        };
        this.getInfo(params02);
      } else {
        // 下一条
        let params = {
          currentId: this.pageInfo.nextId,
        };
        // console.log(params,"下一条")
        // this.getInfo(params);
        this.$router.push({ path: "/newsInfoDetails", query: { params: JSON.stringify(params) } });
        this.pageInfo = JSON.parse(this.$route.query.params);
        this.getLinks();
        let that = this;
        window.onscroll = function() {
          let toTop = document.documentElement.scrollTop || document.body.scrollTop;
          if (toTop > 300) {
            that.isTop = true;
          } else {
            that.isTop = false;
          }
        };
        let params02 = {
          id: this.pageInfo.currentId,
          lastId: this.pageInfo.lastId, //上一条id
          lastTitle: this.pageInfo.lastTitle, //上一条标题
          nextId: this.pageInfo.nextId, //下一条id
          nextTitle: this.pageInfo.nextTitle, //下一条标题
        };
        this.getInfo(params02);
      }
    },
  },
};
</script>
<style scoped>
/* .home {
  background: #fff;
  min-height: 100vh;
} */
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
  /* padding-bottom: 24px; */
  padding-bottom: 40px;
}
.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw; */
  /* background: red; */
  margin: 0 auto;
  position: relative;
}
.text {
  line-height: 30px;
  width: 100%;
  text-align: justify;
  margin-top: 2vw;
  font-size: 16px;
  color: #333;
}
.img {
  width: 100%;
  /* height: 20vw; */
  background: #0e538a;
  margin-top: 3vw;
}
.pageInfo > div{
  max-width: 50%;
}
.pageInfo .btn {
  display: inline-block;
  width: 9vw;
  height: 2vw;
  line-height: 2vw;
  font-size: 1vw;
  border: 1px solid #dbdbdb;
  text-align: center;
  margin-bottom: 1vw;
}
</style>
