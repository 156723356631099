var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('tapbar',{attrs:{"isTop":_vm.isTop}}),_c('div',{staticClass:"content-wrapper"},[_c('moduletitle',{attrs:{"img":_vm.bannerImage,"title":'招 贤 纳 士',"links":_vm.links}}),_c('div',{staticClass:"message"},[_c('p',{staticStyle:{"text-align":"center","font-size":"36px","color":"#083875","font-weight":"bold","margin-top":"3vw"}},[_vm._v(" 热招岗位-社会招聘 ")]),_c('p',{staticClass:"underline"}),_c('div',{staticClass:"search"},[_c('div',{staticClass:"searchLeft"},[_c('span',{staticStyle:{"padding-left":"4vw","border-right":"1px solid #acacac","padding-right":"3vw","cursor":"pointer"},on:{"click":function($event){_vm.isjob = true}}},[_vm._v(_vm._s(_vm.jobType || "职位类型")+" "),_c('span',{staticStyle:{"font-size":"0.7vw","vertical-align":"middle"}},[_vm._v("▼")])]),(_vm.isjob)?_c('div',{staticClass:"job",on:{"mouseleave":function($event){_vm.isjob = false}}},_vm._l((_vm.jobs),function(item,index){return _c('span',{key:index,on:{"click":function($event){_vm.jobType = item.jobType;
                _vm.page = 1;
                _vm.getList();}}},[_vm._v(_vm._s(item.jobType))])}),0):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.job),expression:"job"}],attrs:{"type":"text","placeholder":"搜索岗位"},domProps:{"value":(_vm.job)},on:{"input":function($event){if($event.target.composing){ return; }_vm.job=$event.target.value}}})]),_c('div',{staticClass:"searchBtn",on:{"click":function($event){_vm.page = 1;
            _vm.getList();}}},[_c('img',{attrs:{"src":require("../../assets/image/searchZW.png"),"alt":""}})])]),_c('div',{staticClass:"positionList"},_vm._l((_vm.jobList),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.toLink('/jobDetail', item.id)}}},[_c('div',{staticStyle:{"width":"20%","color":"#124b99"}},[_vm._v(" "+_vm._s(item.job)+" ")]),_c('div',{staticStyle:{"width":"16%"}},[_vm._v(" "+_vm._s(item.years)+" "),_c('span',{staticStyle:{"width":"1px","height":"1vw","background":"#999"}}),_vm._v(" "+_vm._s(item.degree)+" ")]),_c('div',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(item.city)+" ")]),_c('div',{staticStyle:{"width":"12%"}},[_vm._v(_vm._s(item.jobType))]),_c('div',{staticStyle:{"width":"16%"}},[_vm._v(" "+_vm._s(item.department)+" ")]),_c('div',{staticStyle:{"width":"16%"}},[_vm._v(" "+_vm._s(_vm.setNewTime(item.createTime))+" ")]),_c('div',[_vm._v("▶")])])}),0),_c('div',{staticClass:"pageList"},[_c('span',{on:{"click":function($event){return _vm.back()}}},[_vm._v("＜")]),_vm._l((Math.ceil(_vm.count / 10)),function(index){return _c('span',{key:index,style:({
            background: _vm.page == index ? '#0E538A' : '#E9E9EC',
            color: _vm.page == index ? '#fff' : '#000',
          }),on:{"click":function($event){_vm.page = index;
            _vm.getList();}}},[_vm._v(_vm._s(index))])}),_c('span',{on:{"click":function($event){return _vm.forward()}}},[_vm._v("＞")])],2)])],1),_c('bottombar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }