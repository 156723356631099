<template>
  <div class="home">
    <tapbar :isTop="isTop"></tapbar>
    <div class="content-wrapper">
      <moduletitle
        :img="require('/src/assets/image/workIntoHM/1.png')"
        :title="'走 进 华 脉'"
        :links="links"
      ></moduletitle>
      <bar :indexA="3"></bar>
      <div class="message">
        <div class="list">
          <div
            v-for="(item, index) in list"
            :key="index"
            :style="{
              background: index == listIndexA ? '#124b99' : 'none',
              color: index == listIndexA ? '#fff' : '#124b99',
            }"
            @click="
              listIndexA = index;
              page = 1;
              getList();
            "
          >
            {{ item }}
          </div>
        </div>
        <div class="newsList">
          <div
            class="news"
            v-for="(item, index) in newList"
            :key="index"
            @click="toNewsInfoDetails(item.id,index)"
          >
            <div
              class="newsImg"
              :style="{
                background: 'url(' + item.imgurl + ') no-repeat center/cover',
              }"
            >
              <!-- <div class="newsImg"> -->
              <!-- <img :src="require('../../assets/image/new/'+index+'.png')" alt=""> -->
            </div>
            <div class="newsTxt">
              <div class="date">
                <p>{{ breakUpDate(item.createtime)[2] }}</p>
                {{ breakUpDate(item.createtime)[0] }}.{{
                  breakUpDate(item.createtime)[1]
                }}
              </div>
              <div
                style="
                  position: absolute;
                  width: 80%;
                  position: absolute;
                  top: 0;
                  right: 0;
                "
              >
                <div class="newsTitle">{{ item.title }}</div>
                <div class="newsContent">{{ item.present }}</div>
              </div>
              <div class="checkBtn">查看详情</div>
            </div>
          </div>
        </div>

        <div class="pageList">
          <el-pagination
            layout="prev, pager, next"
            :total="count"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <bottombar></bottombar>
  </div>
</template>
<script>
import tapbar from "../../components/top.vue";
import bottombar from "../../components/bottom.vue";
import moduletitle from "../../components/moduleTitle.vue";
import bar from "../../components/bar.vue";
export default {
  components: { tapbar, bottombar, moduletitle, bar },
  data() {
    return {
      isTop: false,
      list: ["公司新闻", "行业动态", "展会信息", "员工活动"],
      newList: [],
      listIndexA: 0,
      count: 0,
      page: 1,
      links: [],
    };
  },
  mounted() {
    this.getLinks();
    let that = this;
    window.onscroll = function () {
      let toTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (toTop > 300) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    };

    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .get("/News/api/getNewList.do", {
          params: {
            type: this.listIndexA + 1,
            limit: 10,
            page: this.page,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.newList = res.data.data;
            console.log(this.newList);
            this.count = res.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    breakUpDate(data) {
      let data01 = data.split(" ");
      let data02 = data01[0].split("-");
      return data02;
    },
    back() {
      if (this.page == 1) {
        return;
      } else {
        this.page = this.page - 1;
      }
      this.getList();
    },
    forward() {
      if (this.page == Math.ceil(this.count / 10)) {
        return;
      } else {
        this.page = this.page + 1;
      }
      this.getList();
    },
    toNewsInfoDetails(id,index) {
      let params;
      if (index) {
        params = {
          currentId: id,
          lastId: this.newList[index-1].id, //上一条id
          lastTitle: this.newList[index-1].title, //上一条标题
          nextId: this.newList[index+1].id, //下一条id
          nextTitle: this.newList[index+1].title, //下一条标题
        };
        
      } else {
        params = {
          currentId: id,
          lastId: null, //上一条id
          lastTitle: null, //上一条标题
          nextId: this.newList[index+1].id, //下一条id
          nextTitle: this.newList[index+1].title, //下一条标题
        };
      }
      this.$router.push({ path: "/newsInfoDetails", query: { params: JSON.stringify(params) } });
      // this.$router.push({ path: "/newsInfoDetails", query: { id: id } });
    },
    getLinks() {
      this.links = [
        {
          name: "首页",
          url: "/index",
          query: {},
        },
        {
          name: "走进华脉",
          url: "/companyProfile",
          query: {},
        },
        {
          name: "新闻资讯",
          url: "/newsInfo",
          query: {},
        },
      ];
    },
    //切换页数
    currentChange(currentPage) {
      this.page = currentPage;
      this.getList();
    },
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-bottom: 306px;
  position: relative;
  z-index: 99;
  background-color: #fff;
}
.home {
  background: #fff;
  min-height: 100vh;
}
.message {
  width: 65vw;
  min-width: 960px;
  /* height: 5vw;
        background: red; */
  margin: 0 auto;
  position: relative;
}
.list {
  width: 100%;
  margin-top: 3vw;
}
.list > div {
  display: inline-block;
  width: 23%;
  border: 1px solid #ddd;
  text-align: center;
  color: #333;
  font-size: 15px;
  line-height: 40px;
  margin-right: 2.66%;
  box-sizing: border-box;
  cursor: pointer;
}
.list > div:last-child {
  margin-right: 0;
}
.newsList {
  margin-top: 3vw;
}
.news {
  padding-bottom: 2vw;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  margin-top: 2vw;
}
.news:last-child {
  border: none;
}
.newsImg {
  width: 35%;
  height: 200px;
  background: #0e538a;
}
.newsTxt {
  width: 60%;
  height: 200px;
  /* background: red; */
  position: absolute;
  top: 0;
  right: 0;
}
.date {
  width: 80px;
  height: 80px;
  border: 1px solid #124b99;
  color: #124b99;
  text-align: center;
  font-size: 16px;
}
.date > p {
  font-size: 30px;
  font-weight: bold;
  margin-top: 2px;
}
.newsTitle {
  font-size: 20px;
  /* width: 80%; */
  /* position: absolute; */
  /* top: 0; */
  /* right: 0; */
}
.newsContent {
  font-size: 16px;
  /* width: 80%; */
  /* position: absolute; */
  margin-top: 10px;
  /* right: 0; */
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.checkBtn {
  /* width: 10vw;
        height: 2.3vw;
        line-height: 2.3vw; */
  background: #124b99;
  padding: 6px 50px;
  position: absolute;
  color: #fff;
  font-size: 16px;
  bottom: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
}
.pageList {
  text-align: right;
  margin-top: 4vw;
  margin-bottom: 4vw;
  cursor: pointer;
}
.pageList > span {
  display: inline-block;
  width: 1.7vw;
  height: 1.7vw;
  line-height: 1.7vw;
  text-align: center;
  font-size: 0.8vw;
  border-radius: 1.7vw;
  background: #e9e9ec;
  margin: 0 0.2vw;
}
.pageList > span:first-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
.pageList > span:first-child:hover {
  background: #0e538a;
  color: #fff;
}
.pageList > span:last-child {
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  border-radius: 2vw;
}
.pageList > span:last-child:hover {
  background: #0e538a;
  color: #fff;
}
</style>